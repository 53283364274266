/*
|--------------------
|      HEADER
|--------------------
*/

body {
  &.menu-active {
    overflow: hidden;
  }
}

/*
|
| Header
|---------
*/
.link-menu {
  text-transform: uppercase;
  color: $very-dark-grey;
  font-size: 14px;
  font-weight: 400;
}

.bandeau {
  color: $white;
  padding: 11px 15px;
  font-size: 45px;
  line-height: 54px;
  width: 100%;
  display: block;
  z-index: 11;
  position: relative;
  text-align: center;
  top: 0;
  @include media-breakpoint-down(md) {
    font-size: 35px;
    line-height: 34px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 28px;
    line-height: 24px;
  }

  div {
    width: inherit;

    img {
      height: 50px;
      margin-right: 10px;
      @include media-breakpoint-down(sm) {
        height: 35px;
      }
    }
  }

  a {
    color: $blue2;
    background-color: $white;
  }
}

#header {
  position: fixed;
  &.sticky-menu {
    background-color: $blue2;
    border-bottom: 1px solid #425c6b;
    .header-container {
      padding-top: 5px;
      padding-bottom: 5px;
      transition: all ease 0.3s;
      .item-logo {
        img {
          height: 70px;
        }
      }
    }
  }
}
#footer {
  position: absolute;
}

#header,
#footer {
  width: 100%;
  z-index: 10;

  .header-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
    transition: all ease 0.3s;
    .item-logo {
      z-index: 1;
      position: relative;
      img {
        height: 160px;
        @include media-breakpoint-down(lg) {
          height: 80px;
        }
      }
    }
  }

  .btn-menu {
    display: none;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //width: 56px;
    //height: 56px;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 25px;
      margin-top: -3px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: white;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
}

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $blue2;
  overflow: auto;
  opacity: 0;
  visibility: hidden;

  .item-container {
    @extend .container;
    margin-top: 110px;
    ul {
      & > li {
        margin-bottom: 5px;
        margin-left: 15px;

        a {
          text-transform: uppercase;
          text-decoration: none;
          color: white;
          font-size: 16px;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;
        }
      }
    }
  }
}

.item-nav {
  position: relative;
  @include media-breakpoint-down(lg) {
    display: none;
  }
  &.item-nav-right {
    .item-menu {
      li {
        text-align: center;
      }
    }
  }
  .item-menu {
    display: table;
    width: 100%;
    table-layout: fixed;
    li {
      display: table-cell;
      width: auto;
      position: relative;
      text-align: center;
      &.current-menu-item,
      &.current_page_parent {
        .item-link {
          &:before {
            content: "";
            height: 1px;
            width: 100%;
            transition: all ease 0.3s;
          }
        }
      }
      .item-link {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 100;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        position: relative;
        &:before {
          content: "";
          height: 1px;
          width: 0%;
          bottom: 0;
          position: absolute;
          background-color: white;
          transition: all ease 0.3s;
        }
        &:hover {
          &:before {
            content: "";
            height: 1px;
            width: 100%;
            transition: all ease 0.3s;
          }
        }
      }
      .nav-drop {
        position: absolute;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        width: 100%;
        padding: 8px 0px 8px 0px;
        top: 26px;
        left: 0px;
        right: 0px;
        z-index: 9;
        display: none;
        background-color: rgba(255, 255, 255, 0.9);
        &.active {
          top: 42px;
        }

        .item0 {
          margin-top: -50px;
          //padding-top: 20px;
        }

        li {
          z-index: 9;
          padding: 0 15px 10px 15px;
          width: 100%;
          display: inline-block;
          text-align: left;
          a {
            font-family: "Avenir Next";
            z-index: 9;
            opacity: 0.8;
            font-size: 16px;
            font-weight: 400;
            line-height: initial;
            display: block;
            color: #000000;
            width: 100%;
            text-decoration: none;
            transition: all ease 0.2s;
          }

          &:first-child {
            margin-top: 0px;
          }
          &:hover {
            color: #000;
            opacity: 0.6;
            transition: all ease 0.2s;
          }
        }
      }
      &.menu-item-has-children {
        &:after {
          content: "";
          width: 14px;
          height: 9px;
          margin-left: 4px;
          display: inline-block;
          background-image: url("../img/icon/arrow-w.svg");
          background-size: contain;
          transition: all ease 0.6s;
        }
        &:hover {
          &:after {
            content: "";
            transform: rotate(-180deg);
            transition: all ease 0.6s;
          }
        }
      }
    }
  }
}
.lang-container {
  position: relative;
  display: inline-flex;
  top: 1px;
  width: 60px;
  @extend .link-menu;

  .lang-item {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    opacity: 0.5;
    text-decoration: none;
    cursor: pointer;
    &.active {
      opacity: 1;
      cursor: initial;
    }
    &:first-child {
      &:after {
        content: "/";
        opacity: 1;
        padding-left: 5px;
        padding-right: 3px;
      }
    }
  }
}
