/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| Colors declaration
|----------------------
|
*/
$black: #000000;
$very-dark-grey: #1a1a1a;
$dark-grey: #424242;
$grey: #767a72;
$grey-alt: #3c4135;
$pink: #f1ddd2;
$light-grey: #dedede;
$very-light-grey: #fafafa;
$white: #ffffff;
$blue2: #28485a;
$blue4: #4b6e83;
$orange: #b4551f;
$blue2-light: #4b6e83;
$white-opacity: rgba(255, 255, 255, 0.8);
$black-opacity: rgba(0, 0, 0, 0.6);

/*
|
| Assign colors
|---------------
|
*/
$border-color: $grey;
$text-color: $very-dark-grey;
$default-background-overlay: $black-opacity;

/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .c-{color}
|
*/
$colors: (
  //Required colors
  "black": $black,
  "very-dark-grey": $very-dark-grey,
  "dark-grey": $dark-grey,
  "grey": $grey,
  "pink": $pink,
  "grey-alt": $grey-alt,
  "orange": $orange,
  "blue2": $blue2,
  "blue4": $blue4,
  "green-light": $blue2-light,
  "light-grey": $light-grey,
  "very-light-grey": $very-light-grey,
  "white": $white
);
