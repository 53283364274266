/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: "Inter", sans-serif;
  color: $grey-alt;
  font-size: $default-font-size;
  line-height: $default-line-height;
  font-weight: $default-font-weight;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
}

h1 {
  font-family: "ITC Avant Garde Gothic Std";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 96px;
  text-transform: initial;
  @include media-breakpoint-down(xl) {
    font-size: 55px;
    line-height: 63px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 46px;
    line-height: 50px;
  }
  @include media-breakpoint-down(md) {
    font-size: 40px;
    line-height: 44px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 34px;
    line-height: 42px;
  }
}
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
}

h2 {
  font-family: "ITC Avant Garde Gothic Std";
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: $blue4;
  @include media-breakpoint-down(xl) {
    font-size: 44px;
    line-height: 52px;
  }
  @include media-breakpoint-down(md) {
    font-size: 28px;
    line-height: 32px;
  }
}

button,
input,
textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.clearfix {
  clear: both;
  width: 100%;
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  max-width: inherit;
}

p {
  sup {
    top: -5px;
    padding-left: 1px;
    font-size: 12px;
    font-weight: initial;
  }
}

sup {
  top: -0.5em;
  font-size: 29px;
  font-weight: bold;
}

.z1 {
  z-index: 1;
}

img,
button {
  display: inline-block;
}

a {
  color: $default-link-color;

  &:hover {
    color: $default-link-color;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.clearfix {
  clear: both;
  width: 100%;
  display: block;
}

.m-a {
  margin: auto;
  display: block;
}

.d-t {
  display: table !important;
}

.td-0 {
  text-decoration: none;
}

.txt-c {
  text-align: center !important;
}

.txt-l {
  text-align: left !important;
}

.txt-r {
  text-align: right !important;
}

.w100 {
  width: 100%;
}
.logo-c {
  display: none;
}
.rel {
  position: relative;
}

.disp-desk {
  display: block !important;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.disp-mob {
  display: none !important;
  @include media-breakpoint-down(md) {
    display: block !important;
  }
}

.disp-desk-lg {
  display: block !important;
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}

.disp-mob-lg {
  display: none !important;
  @include media-breakpoint-down(lg) {
    display: block !important;
  }
}

.dflex {
  display: flex !important;
  vertical-align: middle;
  align-items: center;
}

.block-img {
  display: block;
  overflow: hidden;

  .bg-img {
    display: block;
    background-size: cover !important;
    transform: scale(1);
    transition: all ease 0.3s;
    background: initial;
  }
  &:hover {
    .bg-img {
      transform: scale(1.04);
      transition: all ease 0.3s;
    }
  }
}

.btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  border: 1px solid rgba(40, 72, 90, 0.5);
  padding: 22px 50px;
  text-decoration: none;
  margin: auto;
  display: inline-block;
  text-align: center;
  min-width: 280px;
  color: $grey-alt;
  cursor: pointer;
  box-shadow: inherit;
  animation: leave 0.4s forwards;
  @include media-breakpoint-down(sm) {
    padding: 22px 15px;
  }
  .btn-text {
    margin: auto;
  }
  svg {
    margin-left: 15px;
    margin-top: -2px;
    path {
      transition: all ease 0.4s;
    }
  }
  &:hover {
    animation: hover 0.4s forwards;
    color: white;
    svg {
      transition: all ease 0.4s;
      path {
        fill: white;
        transition: all ease 0.4s;
      }
    }
  }

  &-alt {
    animation: leave-alt 0.4s forwards;
    border: 1px solid white;
    .btn-text {
      color: white;
    }
    &:hover {
      animation: hover-alt 0.4s forwards;
      svg {
        path {
          fill: $blue2;
        }
      }
      .btn-text {
        color: $blue2;
      }
    }
  }
}

@keyframes hover {
  from {
    box-shadow: inset 0 0 0 0.01px $blue2;
  }
  to {
    box-shadow: inset 40em 0 0 0.01px $blue2;
    color: #fff;
  }
}

@keyframes leave {
  from {
    box-shadow: inset -40em 0 0 0.01px $blue2;
    color: #fff;
  }
  to {
    box-shadow: inset 0 0 0 0.01px $blue2;
  }
}

@keyframes hover-alt {
  from {
    box-shadow: inset 0 0 0 0.01px white;
  }
  to {
    box-shadow: inset 40em 0 0 0.01px white;
    color: $blue2;
  }
}

@keyframes leave-alt {
  from {
    box-shadow: inset -40em 0 0 0.01px white;
    color: $blue2;
  }
  to {
    box-shadow: inset 0 0 0 0.01px white;
  }
}

.col-centered {
  margin: 0 auto;
}

.mt-80 {
  margin-top: -80px;
}
