@charset "UTF-8";
/* Bootstrap lightweight */
/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  text-align: left; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

p {
  margin-top: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bold; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container, #header .header-container,
#footer .header-container, #mobile-menu .item-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container, #header .header-container,
    #footer .header-container, #mobile-menu .item-container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container, #header .header-container,
    #footer .header-container, #mobile-menu .item-container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container, #header .header-container,
    #footer .header-container, #mobile-menu .item-container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container, #header .header-container,
    #footer .header-container, #mobile-menu .item-container {
      max-width: 1140px; } }
  @media (min-width: 1400px) {
    .container, #header .header-container,
    #footer .header-container, #mobile-menu .item-container {
      max-width: 1340px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .gravity-form-bootstrap .gform_fields {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

/* Function */
/*
|---------------------------------------------------------------
| Functions
|---------------------------------------------------------------
| Functions used to add classes to generation
|
|
*/
/*
|
| Add Buttons
|-----------------------
|
*/
/*
|
| Add Blocks
|-----------------------
|
*/
/*
|
| Add Links
|-----------------------
|
*/
/* variables */
/*
|---------------------------------------------------------------
| Variables
|---------------------------------------------------------------
| Import variables
|
|
*/
/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Margins and paddings
|
|
*/
/*
|--------------
| Space sizes
|--------------
| Variables used to generate magins & paddings classes (including responsive)
| - Normal classes: .{property}-{size} / .mb-md / margin-bottom: $md  
| - Responsive classes: .{screen}-{up/down/only}-{property}-{size} / .md-down-mb-md /  (max-width: 992px) { margin-bottom: $md }
|
*/
/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/
/*
|
| Colors declaration
|----------------------
|
*/
/*
|
| Assign colors
|---------------
|
*/
/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .c-{color}
|
*/
/*
|---------------------------------------------------------------
| TEXTS
|---------------------------------------------------------------
| Set all text properties
|
|
*/
/*
|
|
| FONT-SIZES
|
|
|----------------------------------------------
| Font sizes settings (for each screen sizes)
|----------------------------------------------
| Variables used to generate font classes (including responsive)
| - Generated classes: .fs-{size}
| - Exemple: .fs-md
|
*/
/*
|
|
| TITLE-SIZES
|
|
|-----------------------------------------------
| Title sizes settings (for each screen sizes)
|-----------------------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
/*
|
| FONT-FAMILIES
|----------------
|
*/
/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes" 
| - Generated classes: .font-{family}
| - Exemple: .font-custom
|
*/
/*
|-----------------
| LETTER-SPACING
|-----------------
| Variables used to generate letter-spacing classes
| - Generated classes: .ls-{size}
| - Exemple: .ls-md
|
*/
/*
|
| LINE-HEIGHT
|--------------
|
*/
/*
|
| Default font settings
|------------------------
|
*/
/*
|
| Default titles settings
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| LINKS
|---------------------------------------------------------------
| Generate and assign links colors
|
|
*/
/*
|
| Links default
|----------------
|
*/
/*
|------------------------
| Links colors creation
|------------------------
| - How to use: Add --> $links-colors: map-set-links($map, $index, $color, $color-hover)
| - Generated classes: .link-{color} & .links-{color} (on parent)
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Buttons base default
|----------------------
|
*/
/*
|
| Buttons size setting
|-----------------------
|
*/
/*
|-------------------
| Buttons creation
|-------------------
| - How to use: $button-colors: map-set-buttons($map, $index, $background-color, $border-color, $text-color)
| - Generated classes: .btn-{size}, .btn-{color}, .btn-icon-{direction}.
|
*/
/*
|---------------------------------------------------------------
| SECTIONS
|---------------------------------------------------------------
| Generate responsive sections
|
|
*/
/*
|-------------------
| Sections setting
|-------------------
| - Generated class: .section
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Bg img default
|----------------------
|
*/
/*
|-------------------
| Bg img creation
|-------------------
| - Generated classes: .bg-img-{size}
|
*/
/* Components */
/*
|---------------------------------------------------------------
| Helpers
|---------------------------------------------------------------
| Define helper classes
|
|
*/
/*
|
| Text Transform
|----------------------------
|
*/
.tt-u {
  text-transform: uppercase !important; }

.tt-l {
  text-transform: lowercase !important; }

.tt-c {
  text-transform: capitalize !important; }

/*
|
| Underline
|------------
|
*/
.td-u {
  text-decoration: underline !important; }

.td-n {
  text-decoration: none !important; }

/*
|
| Font styles
|--------------
|
*/
.fs-i {
  font-style: italic !important; }

.fs-o {
  font-style: oblique !important; }

.fs-n {
  font-style: normal !important; }

/*
|
| Background cover
|-------------------
|
*/
.bg-cover, .bg-cover-top, .bg-cover-bottom {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important; }

.bg-cover-top {
  background-position: center top !important; }

.bg-cover-bottom {
  background-position: center bottom !important; }

/*
|
| Overlays
|-------------
|
*/
.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); }
  .overlay + * {
    position: relative;
    z-index: 2; }

/*
|
| Overflows
|-------------
|
*/
.o-v {
  overflow: visible !important; }

.ox-v {
  overflow-x: visible !important; }

.oy-v {
  overflow-y: visible !important; }

.o-h {
  overflow: hidden !important; }

.ox-h {
  overflow-x: hidden !important; }

.oy-h {
  overflow-y: hidden !important; }

/*
|
| Positions
|-------------
|
*/
.p-r {
  position: relative !important; }

.p-a {
  position: absolute !important; }

.p-f {
  position: fixed !important; }

.p-s {
  position: static !important; }

/*
|
| Flex Alignment
|-----------------
|
*/
.ai-start {
  align-items: flex-start; }

.ai-end {
  align-items: flex-end; }

.ai-center {
  align-items: center; }

.ai-baseline {
  align-items: baseline; }

.ai-stretch {
  align-items: stretch; }

/*
|
| Flex Justify
|---------------
|
*/
.jc-start {
  justify-content: flex-start; }

.jc-end {
  justify-content: flex-end; }

.jc-center {
  justify-content: center; }

.jc-between {
  justify-content: space-between; }

.jc-around {
  justify-content: space-around; }

/*
|
| White space
|--------------
|
*/
.ws-inherit {
  white-space: inherit; }

.ws-normal {
  white-space: normal; }

.ws-nowrap {
  white-space: nowrap; }

.ws-pre {
  white-space: pre; }

.ws-pre-wrap {
  white-space: pre-wrap; }

.ws-pre-line {
  white-space: pre-line; }

/*
|
| Pointer events
|-----------------
|
*/
.pe-inherit {
  pointer-events: inherit; }

.pe-none {
  pointer-events: none; }

.pe-auto {
  pointer-events: auto; }

/*
|
| Optimize animation
|---------------------
|
*/
.optimize-animation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| absolute-full
|-----------------
|
*/
.absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*
|
| Clear floats
|---------------
|
*/
.clear {
  clear: both; }

/*
|
| img full
|-----------
|
*/
.img-full {
  display: inline-block;
  max-width: inherit;
  width: 100%; }

/*
|
| W-100 H-100
|-----------
|
*/
.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

/* Core */
/*
|---------------------------------------------------------------
| MAPS
|---------------------------------------------------------------
| Sass maps used in classes generation
|
|
*/
/*
|
| Font Sizes Map
|---------------------
|
*/
/*
|
| Title Sizes Map
|---------------------
|
*/
/*
|
| Buttons Sizes Map
|---------------------
|
*/
/*
|
| Margins & Paddings Maps
|--------------------------
|
*/
/*
|-------------------------------
| Font weight classes creation
|-------------------------------
| How to use: add lines to create more "font weight classes"
| - Generated classes: .font-{weight}
| - Exemple: .font-regular
|
*/
/*
|
| Font-weights
|---------------
|
*/
/*
|
| Text Align Map
|--------------------------
|
*/
/*
|-------------------------------
| Line-height classes creation
|-------------------------------
| How to use: add lines to create more "line-height classes"
| - Generated classes: .lh-{weight}
| - Exemple: .lh-sm
|
*/
/*
|
| Letter Spacing Map
|--------------------------
|
*/
/*
|
| Display Map
|--------------------------
|
*/
/*
|
| Section Map
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| MIXINS
|---------------------------------------------------------------
| Mixins called from generator to generate classes
|
|
*/
/*
|
| Commonly used mixins
|-----------------------
|
*/
/*
|
| Margins & Paddings
|-----------------------
|
*/
/*
|
| Responsive Margins & Paddings
|--------------------------------
|
*/
/*
|
| Font-sizes
|-----------------------
|
*/
/*
|
| Titles
|-----------------------
|
*/
/*
|
| Links
|-----------------------
|
*/
/*
|
| Buttons
|-----------------------
|
*/
/*
|
| Hidden classes
|-----------------------
|
*/
/*
|
| Display
|-----------------------
|
*/
/*
|
| Text Align
|-----------------------
|
*/
/*
|
| Sections
|-----------------------
|
*/
/*
|
| Bg imgs
|-------------
|
*/
/*
|---------------------------------------------------------------
| CLASS GENERATOR
|---------------------------------------------------------------
| Mixins calls to generate classes
|
|
*/
/*------- DISPLAY -------*/
@media (max-width: 1199.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1200px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1199.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1200px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1199.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1200px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1200px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1200px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1199.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1200px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- FONT-SIZES -------*/
.fs-xs {
  font-size: 12px !important; }
  @media (max-width: 1199.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 991.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 767.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 575.98px) {
    .fs-xs {
      font-size: 12px !important; } }

.fs-sm {
  font-size: 16px !important; }
  @media (max-width: 1199.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 991.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 767.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 575.98px) {
    .fs-sm {
      font-size: 16px !important; } }

.fs-md {
  font-size: 18px !important; }
  @media (max-width: 1199.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 991.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 767.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 575.98px) {
    .fs-md {
      font-size: 18px !important; } }

.fs-lg {
  font-size: 28px !important; }
  @media (max-width: 1199.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 991.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 767.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 575.98px) {
    .fs-lg {
      font-size: 28px !important; } }

.fs-xl {
  font-size: 36px !important; }
  @media (max-width: 1199.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xl {
      font-size: 36px !important; } }

.fs-xxl {
  font-size: 36px !important; }
  @media (max-width: 1199.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 991.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xxl {
      font-size: 36px !important; } }

/*------- TITLES -------*/
.title-xs, .cms h6 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 16px; }
  @media (max-width: 1199.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 991.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 767.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 575.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }

.title-sm, .cms h5 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 18px; }
  @media (max-width: 1199.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 991.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 767.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 575.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }

.title-md, .cms h4 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 24px; }
  @media (max-width: 1199.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 991.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 767.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 575.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }

.title-lg, .cms h3 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 30px; }
  @media (max-width: 1199.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 991.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 767.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 575.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }

.title-xl {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 50px; }
  @media (max-width: 1199.98px) {
    .title-xl {
      font-size: 50px; } }
  @media (max-width: 991.98px) {
    .title-xl {
      font-size: 42px; } }
  @media (max-width: 767.98px) {
    .title-xl {
      font-size: 42px; } }
  @media (max-width: 575.98px) {
    .title-xl {
      font-size: 32px; } }

.title-xxl, .cms h1 {
  color: inherit;
  font-family: "graphik", sans-serif;
  font-weight: 400;
  line-height: 1.2;
  font-size: 62px; }
  @media (max-width: 1199.98px) {
    .title-xxl, .cms h1 {
      font-size: 50px; } }
  @media (max-width: 991.98px) {
    .title-xxl, .cms h1 {
      font-size: 42px; } }
  @media (max-width: 767.98px) {
    .title-xxl, .cms h1 {
      font-size: 36px; } }
  @media (max-width: 575.98px) {
    .title-xxl, .cms h1 {
      font-size: 36px; } }

/*------- FONT-FAMILY -------*/
.ff-default {
  font-family: "Inter", sans-serif ; }

.ff-custom {
  font-family: "graphik", sans-serif ; }

.ff-extra {
  font-family: "canela", sans-serif ; }

/*------- FONT-WEIGHTS -------*/
.fw-100 {
  font-weight: 100 ; }

.fw-200 {
  font-weight: 200 ; }

.fw-300 {
  font-weight: 300 ; }

.fw-400 {
  font-weight: 400 ; }

.fw-500 {
  font-weight: 500 ; }

.fw-600 {
  font-weight: 600 ; }

.fw-700 {
  font-weight: 700 ; }

.fw-800 {
  font-weight: 800 ; }

.fw-900 {
  font-weight: 900 ; }

/*------- LINE-HEIGHTS -------*/
.lh-xs {
  line-height: 1 ; }

.lh-sm {
  line-height: 1.2 ; }

.lh-md {
  line-height: 1.5 ; }

.lh-lg {
  line-height: 1.6 ; }

.lh-xl {
  line-height: 1.8 ; }

.lh-xxl {
  line-height: 2 ; }

/*------- COLORS -------*/
.c-black {
  color: #000000 ; }

.c-very-dark-grey {
  color: #1a1a1a ; }

.c-dark-grey {
  color: #424242 ; }

.c-grey {
  color: #767a72 ; }

.c-pink {
  color: #f1ddd2 ; }

.c-grey-alt {
  color: #3c4135 ; }

.c-orange {
  color: #b4551f ; }

.c-blue2 {
  color: #28485a ; }

.c-blue4 {
  color: #4b6e83 ; }

.c-green-light {
  color: #4b6e83 ; }

.c-light-grey {
  color: #dedede ; }

.c-very-light-grey {
  color: #fafafa ; }

.c-white {
  color: #ffffff ; }

/*------- LINKS -------*/
.link-white {
  color: #ffffff; }
  .link-white:hover {
    color: #dedede; }

.links-white a {
  color: #ffffff; }
  .links-white a:hover {
    color: #dedede; }

.link-black {
  color: #000000; }
  .link-black:hover {
    color: #767a72; }

.links-black a {
  color: #000000; }
  .links-black a:hover {
    color: #767a72; }

/*------- BUTTONS -------*/
.btn-black {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff; }
  .btn-black:hover {
    color: #ffffff; }
  .btn-black .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-black .icon svg,
    .btn-black .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-black.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-black.btn-icon-right .icon {
      right: 20px; }
  .btn-black.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-black.btn-icon-left .icon {
      left: 20px; }

.btn-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000; }
  .btn-white:hover {
    color: #000000; }
  .btn-white .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-white .icon svg,
    .btn-white .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-white.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-white.btn-icon-right .icon {
      right: 20px; }
  .btn-white.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-white.btn-icon-left .icon {
      left: 20px; }

.btn-xs {
  padding: 2px 5px;
  font-size: 10px; }
  .btn-xs .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 10px; }
    .btn-xs .icon svg,
    .btn-xs .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-xs.btn-icon-right {
    text-align: right;
    padding-right: 25px; }
    .btn-xs.btn-icon-right .icon {
      right: 5px; }
  .btn-xs.btn-icon-left {
    text-align: left;
    padding-left: 25px; }
    .btn-xs.btn-icon-left .icon {
      left: 5px; }

.btn-sm {
  padding: 5px 10px;
  font-size: 14px; }
  .btn-sm .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 20px; }
    .btn-sm .icon svg,
    .btn-sm .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-sm.btn-icon-right {
    text-align: right;
    padding-right: 40px; }
    .btn-sm.btn-icon-right .icon {
      right: 10px; }
  .btn-sm.btn-icon-left {
    text-align: left;
    padding-left: 40px; }
    .btn-sm.btn-icon-left .icon {
      left: 10px; }

.btn-md {
  padding: 10px 20px;
  font-size: 16px; }
  .btn-md .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-md .icon svg,
    .btn-md .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-md.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-md.btn-icon-right .icon {
      right: 20px; }
  .btn-md.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-md.btn-icon-left .icon {
      left: 20px; }

.btn-lg {
  padding: 20px 30px;
  font-size: 20px; }
  .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 50px; }
    .btn-lg .icon svg,
    .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-lg.btn-icon-right {
    text-align: right;
    padding-right: 90px; }
    .btn-lg.btn-icon-right .icon {
      right: 30px; }
  .btn-lg.btn-icon-left {
    text-align: left;
    padding-left: 90px; }
    .btn-lg.btn-icon-left .icon {
      left: 30px; }

/*------- BACKGROUND-COLORS -------*/
.bg-black {
  background-color: #000000 ; }

.bg-very-dark-grey {
  background-color: #1a1a1a ; }

.bg-dark-grey {
  background-color: #424242 ; }

.bg-grey {
  background-color: #767a72 ; }

.bg-pink {
  background-color: #f1ddd2 ; }

.bg-grey-alt {
  background-color: #3c4135 ; }

.bg-orange {
  background-color: #b4551f ; }

.bg-blue2 {
  background-color: #28485a ; }

.bg-blue4 {
  background-color: #4b6e83 ; }

.bg-green-light {
  background-color: #4b6e83 ; }

.bg-light-grey {
  background-color: #dedede ; }

.bg-very-light-grey {
  background-color: #fafafa ; }

.bg-white {
  background-color: #ffffff ; }

/*------- TEXT-ALIGN -------*/
.ta-r {
  text-align: right ; }

.ta-l {
  text-align: left ; }

.ta-c {
  text-align: center ; }

.ta-j {
  text-align: justify ; }

@media (max-width: 1199.98px) {
  .lg-down-ta-r {
    text-align: right; } }

@media (max-width: 991.98px) {
  .md-down-ta-r {
    text-align: right; } }

@media (max-width: 767.98px) {
  .sm-down-ta-r {
    text-align: right; } }

@media (max-width: 575.98px) {
  .xs-down-ta-r {
    text-align: right; } }

.xs-up-ta-r {
  text-align: right; }

@media (min-width: 576px) {
  .sm-up-ta-r {
    text-align: right; } }

@media (min-width: 768px) {
  .md-up-ta-r {
    text-align: right; } }

@media (min-width: 992px) {
  .lg-up-ta-r {
    text-align: right; } }

@media (min-width: 1200px) {
  .xl-up-ta-r {
    text-align: right; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-l {
    text-align: left; } }

@media (max-width: 991.98px) {
  .md-down-ta-l {
    text-align: left; } }

@media (max-width: 767.98px) {
  .sm-down-ta-l {
    text-align: left; } }

@media (max-width: 575.98px) {
  .xs-down-ta-l {
    text-align: left; } }

.xs-up-ta-l {
  text-align: left; }

@media (min-width: 576px) {
  .sm-up-ta-l {
    text-align: left; } }

@media (min-width: 768px) {
  .md-up-ta-l {
    text-align: left; } }

@media (min-width: 992px) {
  .lg-up-ta-l {
    text-align: left; } }

@media (min-width: 1200px) {
  .xl-up-ta-l {
    text-align: left; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-c {
    text-align: center; } }

@media (max-width: 991.98px) {
  .md-down-ta-c {
    text-align: center; } }

@media (max-width: 767.98px) {
  .sm-down-ta-c {
    text-align: center; } }

@media (max-width: 575.98px) {
  .xs-down-ta-c {
    text-align: center; } }

.xs-up-ta-c {
  text-align: center; }

@media (min-width: 576px) {
  .sm-up-ta-c {
    text-align: center; } }

@media (min-width: 768px) {
  .md-up-ta-c {
    text-align: center; } }

@media (min-width: 992px) {
  .lg-up-ta-c {
    text-align: center; } }

@media (min-width: 1200px) {
  .xl-up-ta-c {
    text-align: center; } }

@media (max-width: 1199.98px) {
  .lg-down-ta-j {
    text-align: justify; } }

@media (max-width: 991.98px) {
  .md-down-ta-j {
    text-align: justify; } }

@media (max-width: 767.98px) {
  .sm-down-ta-j {
    text-align: justify; } }

@media (max-width: 575.98px) {
  .xs-down-ta-j {
    text-align: justify; } }

.xs-up-ta-j {
  text-align: justify; }

@media (min-width: 576px) {
  .sm-up-ta-j {
    text-align: justify; } }

@media (min-width: 768px) {
  .md-up-ta-j {
    text-align: justify; } }

@media (min-width: 992px) {
  .lg-up-ta-j {
    text-align: justify; } }

@media (min-width: 1200px) {
  .xl-up-ta-j {
    text-align: justify; } }

/*------- LETTER SPACING -------*/
.ls-xs {
  letter-spacing: 1px ; }

.ls-sm {
  letter-spacing: 2px ; }

.ls-md {
  letter-spacing: 3px ; }

.ls-lg {
  letter-spacing: 4px ; }

.ls-xl {
  letter-spacing: 5px ; }

.ls-xxl {
  letter-spacing: 6px ; }

/*------- DISPLAYS -------*/
.d-none {
  display: none ; }

.d-i {
  display: inline ; }

.d-b {
  display: block ; }

.d-ib {
  display: inline-block ; }

.d-f {
  display: flex ; }

.d-if {
  display: inline-flex ; }

@media (max-width: 1199.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 991.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 992px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1200px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1199.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 991.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 992px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1200px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1199.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 991.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 992px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1200px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 991.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 992px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1200px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1199.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 991.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 992px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1200px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1199.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 991.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 992px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1200px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- SECTIONS -------*/
.section {
  position: relative;
  padding-top: 100px !important;
  padding-bottom: 100px !important; }
  @media (max-width: 1199.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 991.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 767.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 575.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }

/*------- BG IMG -------*/
.bg-img, .bg-img-square, .bg-img-almost-square, .bg-img-landscape, .bg-img-landscape-sm, .bg-img-portrait {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000; }
  .bg-img:after, .bg-img-square:after, .bg-img-almost-square:after, .bg-img-landscape:after, .bg-img-landscape-sm:after, .bg-img-portrait:after {
    content: "";
    display: block; }

.bg-img-square:after {
  padding-bottom: 100%; }

.bg-img-almost-square:after {
  padding-bottom: 85%; }

.bg-img-landscape:after {
  padding-bottom: 70%; }

.bg-img-landscape-sm:after {
  padding-bottom: 60%; }

.bg-img-portrait:after {
  padding-bottom: 130%; }

/*------- MARGINS & PADDINGS -------*/
.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs {
  margin: 5px !important; }

.mt-xs {
  margin-top: 5px !important; }

.mb-xs {
  margin-bottom: 5px !important; }

.my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs {
  margin-left: 5px !important; }

.mr-xs {
  margin-right: 5px !important; }

.mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs {
  padding: 5px !important; }

.pt-xs {
  padding-top: 5px !important; }

.pb-xs {
  padding-bottom: 5px !important; }

.py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs {
  padding-left: 5px !important; }

.pr-xs {
  padding-right: 5px !important; }

.px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm {
  margin: 15px !important; }

.mt-sm {
  margin-top: 15px !important; }

.mb-sm {
  margin-bottom: 15px !important; }

.my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm {
  margin-left: 15px !important; }

.mr-sm {
  margin-right: 15px !important; }

.mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm {
  padding: 15px !important; }

.pt-sm {
  padding-top: 15px !important; }

.pb-sm {
  padding-bottom: 15px !important; }

.py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm {
  padding-left: 15px !important; }

.pr-sm {
  padding-right: 15px !important; }

.px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md {
  margin: 30px !important; }

.mt-md {
  margin-top: 30px !important; }

.mb-md {
  margin-bottom: 30px !important; }

.my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md {
  margin-left: 30px !important; }

.mr-md {
  margin-right: 30px !important; }

.mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md {
  padding: 30px !important; }

.pt-md {
  padding-top: 30px !important; }

.pb-md {
  padding-bottom: 30px !important; }

.py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md {
  padding-left: 30px !important; }

.pr-md {
  padding-right: 30px !important; }

.px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg {
  margin: 50px !important; }

.mt-lg {
  margin-top: 50px !important; }

.mb-lg {
  margin-bottom: 50px !important; }

.my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg {
  margin-left: 50px !important; }

.mr-lg {
  margin-right: 50px !important; }

.mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg {
  padding: 50px !important; }

.pt-lg {
  padding-top: 50px !important; }

.pb-lg {
  padding-bottom: 50px !important; }

.py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg {
  padding-left: 50px !important; }

.pr-lg {
  padding-right: 50px !important; }

.px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl {
  margin: 70px !important; }

.mt-xl {
  margin-top: 70px !important; }

.mb-xl {
  margin-bottom: 70px !important; }

.my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl {
  margin-left: 70px !important; }

.mr-xl {
  margin-right: 70px !important; }

.mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl {
  padding: 70px !important; }

.pt-xl {
  padding-top: 70px !important; }

.pb-xl {
  padding-bottom: 70px !important; }

.py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl {
  padding-left: 70px !important; }

.pr-xl {
  padding-right: 70px !important; }

.px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl {
  margin: 140px !important; }

.mt-xxl {
  margin-top: 140px !important; }

.mb-xxl {
  margin-bottom: 140px !important; }

.my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl {
  margin-left: 140px !important; }

.mr-xxl {
  margin-right: 140px !important; }

.mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl {
  padding: 140px !important; }

.pt-xxl {
  padding-top: 140px !important; }

.pb-xxl {
  padding-bottom: 140px !important; }

.py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl {
  padding-left: 140px !important; }

.pr-xxl {
  padding-right: 140px !important; }

.px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.m-0-child > * {
  margin: 0 !important; }

.m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.mt-0-child > * {
  margin-top: 0 !important; }

.mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.mb-0-child > * {
  margin-bottom: 0 !important; }

.mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0-child > * {
  margin-left: 0 !important; }

.ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.mr-0-child > * {
  margin-right: 0 !important; }

.mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0-child > * {
  padding: 0 !important; }

.p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.pt-0-child > * {
  padding-top: 0 !important; }

.pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.pb-0-child > * {
  padding-bottom: 0 !important; }

.pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0-child > * {
  padding-left: 0 !important; }

.pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.pr-0-child > * {
  padding-right: 0 !important; }

.pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs-child > * {
  margin: 5px !important; }

.m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.mt-xs-child > * {
  margin-top: 5px !important; }

.mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.mb-xs-child > * {
  margin-bottom: 5px !important; }

.mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs-child > * {
  margin-left: 5px !important; }

.ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.mr-xs-child > * {
  margin-right: 5px !important; }

.mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs-child > * {
  padding: 5px !important; }

.p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.pt-xs-child > * {
  padding-top: 5px !important; }

.pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.pb-xs-child > * {
  padding-bottom: 5px !important; }

.pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs-child > * {
  padding-left: 5px !important; }

.pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.pr-xs-child > * {
  padding-right: 5px !important; }

.pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm-child > * {
  margin: 15px !important; }

.m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.mt-sm-child > * {
  margin-top: 15px !important; }

.mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.mb-sm-child > * {
  margin-bottom: 15px !important; }

.mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm-child > * {
  margin-left: 15px !important; }

.ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.mr-sm-child > * {
  margin-right: 15px !important; }

.mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm-child > * {
  padding: 15px !important; }

.p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.pt-sm-child > * {
  padding-top: 15px !important; }

.pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.pb-sm-child > * {
  padding-bottom: 15px !important; }

.pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm-child > * {
  padding-left: 15px !important; }

.pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.pr-sm-child > * {
  padding-right: 15px !important; }

.pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md-child > * {
  margin: 30px !important; }

.m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.mt-md-child > * {
  margin-top: 30px !important; }

.mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.mb-md-child > * {
  margin-bottom: 30px !important; }

.mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md-child > * {
  margin-left: 30px !important; }

.ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.mr-md-child > * {
  margin-right: 30px !important; }

.mr-md-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md-child > * {
  padding: 30px !important; }

.p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.pt-md-child > * {
  padding-top: 30px !important; }

.pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.pb-md-child > * {
  padding-bottom: 30px !important; }

.pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md-child > * {
  padding-left: 30px !important; }

.pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.pr-md-child > * {
  padding-right: 30px !important; }

.pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg-child > * {
  margin: 50px !important; }

.m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.mt-lg-child > * {
  margin-top: 50px !important; }

.mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.mb-lg-child > * {
  margin-bottom: 50px !important; }

.mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg-child > * {
  margin-left: 50px !important; }

.ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.mr-lg-child > * {
  margin-right: 50px !important; }

.mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg-child > * {
  padding: 50px !important; }

.p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.pt-lg-child > * {
  padding-top: 50px !important; }

.pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.pb-lg-child > * {
  padding-bottom: 50px !important; }

.pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg-child > * {
  padding-left: 50px !important; }

.pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.pr-lg-child > * {
  padding-right: 50px !important; }

.pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl-child > * {
  margin: 70px !important; }

.m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.mt-xl-child > * {
  margin-top: 70px !important; }

.mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.mb-xl-child > * {
  margin-bottom: 70px !important; }

.mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl-child > * {
  margin-left: 70px !important; }

.ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.mr-xl-child > * {
  margin-right: 70px !important; }

.mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl-child > * {
  padding: 70px !important; }

.p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.pt-xl-child > * {
  padding-top: 70px !important; }

.pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.pb-xl-child > * {
  padding-bottom: 70px !important; }

.pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl-child > * {
  padding-left: 70px !important; }

.pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.pr-xl-child > * {
  padding-right: 70px !important; }

.pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl-child > * {
  margin: 140px !important; }

.m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.mt-xxl-child > * {
  margin-top: 140px !important; }

.mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.mb-xxl-child > * {
  margin-bottom: 140px !important; }

.mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl-child > * {
  margin-left: 140px !important; }

.ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.mr-xxl-child > * {
  margin-right: 140px !important; }

.mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl-child > * {
  padding: 140px !important; }

.p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.pt-xxl-child > * {
  padding-top: 140px !important; }

.pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.pb-xxl-child > * {
  padding-bottom: 140px !important; }

.pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl-child > * {
  padding-left: 140px !important; }

.pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.pr-xxl-child > * {
  padding-right: 140px !important; }

.pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (max-width: 1199.98px) {
  .lg-down-m-0 {
    margin: 0 !important; }
  .lg-down-m-0-child > * {
    margin: 0 !important; }
  .lg-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-0 {
    margin-top: 0 !important; }
  .lg-down-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-0 {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-0 {
    margin-left: 0 !important; }
  .lg-down-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-0 {
    margin-right: 0 !important; }
  .lg-down-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-0 {
    padding: 0 !important; }
  .lg-down-p-0-child > * {
    padding: 0 !important; }
  .lg-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-0 {
    padding-top: 0 !important; }
  .lg-down-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-0 {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-0 {
    padding-left: 0 !important; }
  .lg-down-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-0 {
    padding-right: 0 !important; }
  .lg-down-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xs {
    margin: 5px !important; }
  .lg-down-m-xs-child > * {
    margin: 5px !important; }
  .lg-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xs {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xs {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xs {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xs {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xs {
    padding: 5px !important; }
  .lg-down-p-xs-child > * {
    padding: 5px !important; }
  .lg-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xs {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xs {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xs {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xs {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-sm {
    margin: 15px !important; }
  .lg-down-m-sm-child > * {
    margin: 15px !important; }
  .lg-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-sm {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-sm {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-sm {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-sm {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-sm {
    padding: 15px !important; }
  .lg-down-p-sm-child > * {
    padding: 15px !important; }
  .lg-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-sm {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-sm {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-sm {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-sm {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-md {
    margin: 30px !important; }
  .lg-down-m-md-child > * {
    margin: 30px !important; }
  .lg-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-md {
    margin-top: 30px !important; }
  .lg-down-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-md {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-md {
    margin-left: 30px !important; }
  .lg-down-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-md {
    margin-right: 30px !important; }
  .lg-down-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-md {
    padding: 30px !important; }
  .lg-down-p-md-child > * {
    padding: 30px !important; }
  .lg-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-md {
    padding-top: 30px !important; }
  .lg-down-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-md {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-md {
    padding-left: 30px !important; }
  .lg-down-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-md {
    padding-right: 30px !important; }
  .lg-down-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-lg {
    margin: 50px !important; }
  .lg-down-m-lg-child > * {
    margin: 50px !important; }
  .lg-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-lg {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-lg {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-lg {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-lg {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-lg {
    padding: 50px !important; }
  .lg-down-p-lg-child > * {
    padding: 50px !important; }
  .lg-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-lg {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-lg {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-lg {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-lg {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xl {
    margin: 70px !important; }
  .lg-down-m-xl-child > * {
    margin: 70px !important; }
  .lg-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xl {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xl {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xl {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xl {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xl {
    padding: 70px !important; }
  .lg-down-p-xl-child > * {
    padding: 70px !important; }
  .lg-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xl {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xl {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xl {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xl {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-m-xxl {
    margin: 140px !important; }
  .lg-down-m-xxl-child > * {
    margin: 140px !important; }
  .lg-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mt-xxl {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-ml-xxl {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mr-xxl {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-p-xxl {
    padding: 140px !important; }
  .lg-down-p-xxl-child > * {
    padding: 140px !important; }
  .lg-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pt-xxl {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pl-xxl {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-pr-xxl {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 1199.98px) {
  .lg-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-0 {
    margin: 0 !important; }
  .md-down-m-0-child > * {
    margin: 0 !important; }
  .md-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-0 {
    margin-top: 0 !important; }
  .md-down-mt-0-child > * {
    margin-top: 0 !important; }
  .md-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-0 {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-0 {
    margin-left: 0 !important; }
  .md-down-ml-0-child > * {
    margin-left: 0 !important; }
  .md-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-0 {
    margin-right: 0 !important; }
  .md-down-mr-0-child > * {
    margin-right: 0 !important; }
  .md-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-p-0 {
    padding: 0 !important; }
  .md-down-p-0-child > * {
    padding: 0 !important; }
  .md-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-0 {
    padding-top: 0 !important; }
  .md-down-pt-0-child > * {
    padding-top: 0 !important; }
  .md-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-0 {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-0 {
    padding-left: 0 !important; }
  .md-down-pl-0-child > * {
    padding-left: 0 !important; }
  .md-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-0 {
    padding-right: 0 !important; }
  .md-down-pr-0-child > * {
    padding-right: 0 !important; }
  .md-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xs {
    margin: 5px !important; }
  .md-down-m-xs-child > * {
    margin: 5px !important; }
  .md-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xs {
    margin-top: 5px !important; }
  .md-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xs {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xs {
    margin-left: 5px !important; }
  .md-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xs {
    margin-right: 5px !important; }
  .md-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xs {
    padding: 5px !important; }
  .md-down-p-xs-child > * {
    padding: 5px !important; }
  .md-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xs {
    padding-top: 5px !important; }
  .md-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xs {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xs {
    padding-left: 5px !important; }
  .md-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xs {
    padding-right: 5px !important; }
  .md-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-sm {
    margin: 15px !important; }
  .md-down-m-sm-child > * {
    margin: 15px !important; }
  .md-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-sm {
    margin-top: 15px !important; }
  .md-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-sm {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-sm {
    margin-left: 15px !important; }
  .md-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-sm {
    margin-right: 15px !important; }
  .md-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-sm {
    padding: 15px !important; }
  .md-down-p-sm-child > * {
    padding: 15px !important; }
  .md-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-sm {
    padding-top: 15px !important; }
  .md-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-sm {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-sm {
    padding-left: 15px !important; }
  .md-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-sm {
    padding-right: 15px !important; }
  .md-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-md {
    margin: 30px !important; }
  .md-down-m-md-child > * {
    margin: 30px !important; }
  .md-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-md {
    margin-top: 30px !important; }
  .md-down-mt-md-child > * {
    margin-top: 30px !important; }
  .md-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-md {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-md {
    margin-left: 30px !important; }
  .md-down-ml-md-child > * {
    margin-left: 30px !important; }
  .md-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-md {
    margin-right: 30px !important; }
  .md-down-mr-md-child > * {
    margin-right: 30px !important; }
  .md-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-md {
    padding: 30px !important; }
  .md-down-p-md-child > * {
    padding: 30px !important; }
  .md-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-md {
    padding-top: 30px !important; }
  .md-down-pt-md-child > * {
    padding-top: 30px !important; }
  .md-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-md {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-md {
    padding-left: 30px !important; }
  .md-down-pl-md-child > * {
    padding-left: 30px !important; }
  .md-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-md {
    padding-right: 30px !important; }
  .md-down-pr-md-child > * {
    padding-right: 30px !important; }
  .md-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-lg {
    margin: 50px !important; }
  .md-down-m-lg-child > * {
    margin: 50px !important; }
  .md-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-lg {
    margin-top: 50px !important; }
  .md-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-lg {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-lg {
    margin-left: 50px !important; }
  .md-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-lg {
    margin-right: 50px !important; }
  .md-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-lg {
    padding: 50px !important; }
  .md-down-p-lg-child > * {
    padding: 50px !important; }
  .md-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-lg {
    padding-top: 50px !important; }
  .md-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-lg {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-lg {
    padding-left: 50px !important; }
  .md-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-lg {
    padding-right: 50px !important; }
  .md-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xl {
    margin: 70px !important; }
  .md-down-m-xl-child > * {
    margin: 70px !important; }
  .md-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xl {
    margin-top: 70px !important; }
  .md-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xl {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xl {
    margin-left: 70px !important; }
  .md-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xl {
    margin-right: 70px !important; }
  .md-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xl {
    padding: 70px !important; }
  .md-down-p-xl-child > * {
    padding: 70px !important; }
  .md-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xl {
    padding-top: 70px !important; }
  .md-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xl {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xl {
    padding-left: 70px !important; }
  .md-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xl {
    padding-right: 70px !important; }
  .md-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 991.98px) {
  .md-down-m-xxl {
    margin: 140px !important; }
  .md-down-m-xxl-child > * {
    margin: 140px !important; }
  .md-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mt-xxl {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mb-xxl {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-ml-xxl {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mr-xxl {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-p-xxl {
    padding: 140px !important; }
  .md-down-p-xxl-child > * {
    padding: 140px !important; }
  .md-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pt-xxl {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pb-xxl {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pl-xxl {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-pr-xxl {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 991.98px) {
  .md-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-0 {
    margin: 0 !important; }
  .sm-down-m-0-child > * {
    margin: 0 !important; }
  .sm-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-0 {
    margin-top: 0 !important; }
  .sm-down-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-0 {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-0 {
    margin-left: 0 !important; }
  .sm-down-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-0 {
    margin-right: 0 !important; }
  .sm-down-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-0 {
    padding: 0 !important; }
  .sm-down-p-0-child > * {
    padding: 0 !important; }
  .sm-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-0 {
    padding-top: 0 !important; }
  .sm-down-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-0 {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-0 {
    padding-left: 0 !important; }
  .sm-down-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-0 {
    padding-right: 0 !important; }
  .sm-down-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xs {
    margin: 5px !important; }
  .sm-down-m-xs-child > * {
    margin: 5px !important; }
  .sm-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xs {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xs {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xs {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xs {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xs {
    padding: 5px !important; }
  .sm-down-p-xs-child > * {
    padding: 5px !important; }
  .sm-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xs {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xs {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xs {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xs {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-sm {
    margin: 15px !important; }
  .sm-down-m-sm-child > * {
    margin: 15px !important; }
  .sm-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-sm {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-sm {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-sm {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-sm {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-sm {
    padding: 15px !important; }
  .sm-down-p-sm-child > * {
    padding: 15px !important; }
  .sm-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-sm {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-sm {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-sm {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-sm {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-md {
    margin: 30px !important; }
  .sm-down-m-md-child > * {
    margin: 30px !important; }
  .sm-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-md {
    margin-top: 30px !important; }
  .sm-down-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-md {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-md {
    margin-left: 30px !important; }
  .sm-down-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-md {
    margin-right: 30px !important; }
  .sm-down-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-md {
    padding: 30px !important; }
  .sm-down-p-md-child > * {
    padding: 30px !important; }
  .sm-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-md {
    padding-top: 30px !important; }
  .sm-down-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-md {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-md {
    padding-left: 30px !important; }
  .sm-down-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-md {
    padding-right: 30px !important; }
  .sm-down-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-lg {
    margin: 50px !important; }
  .sm-down-m-lg-child > * {
    margin: 50px !important; }
  .sm-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-lg {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-lg {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-lg {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-lg {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-lg {
    padding: 50px !important; }
  .sm-down-p-lg-child > * {
    padding: 50px !important; }
  .sm-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-lg {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-lg {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-lg {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-lg {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xl {
    margin: 70px !important; }
  .sm-down-m-xl-child > * {
    margin: 70px !important; }
  .sm-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xl {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xl {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xl {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xl {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xl {
    padding: 70px !important; }
  .sm-down-p-xl-child > * {
    padding: 70px !important; }
  .sm-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xl {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xl {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xl {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xl {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xxl {
    margin: 140px !important; }
  .sm-down-m-xxl-child > * {
    margin: 140px !important; }
  .sm-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xxl {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xxl {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xxl {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xxl {
    padding: 140px !important; }
  .sm-down-p-xxl-child > * {
    padding: 140px !important; }
  .sm-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xxl {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xxl {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xxl {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-0 {
    margin: 0 !important; }
  .xs-down-m-0-child > * {
    margin: 0 !important; }
  .xs-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-0 {
    margin-top: 0 !important; }
  .xs-down-mt-0-child > * {
    margin-top: 0 !important; }
  .xs-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-0 {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-0 {
    margin-left: 0 !important; }
  .xs-down-ml-0-child > * {
    margin-left: 0 !important; }
  .xs-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-0 {
    margin-right: 0 !important; }
  .xs-down-mr-0-child > * {
    margin-right: 0 !important; }
  .xs-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-0 {
    padding: 0 !important; }
  .xs-down-p-0-child > * {
    padding: 0 !important; }
  .xs-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-0 {
    padding-top: 0 !important; }
  .xs-down-pt-0-child > * {
    padding-top: 0 !important; }
  .xs-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-0 {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-0 {
    padding-left: 0 !important; }
  .xs-down-pl-0-child > * {
    padding-left: 0 !important; }
  .xs-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-0 {
    padding-right: 0 !important; }
  .xs-down-pr-0-child > * {
    padding-right: 0 !important; }
  .xs-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xs {
    margin: 5px !important; }
  .xs-down-m-xs-child > * {
    margin: 5px !important; }
  .xs-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xs {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xs {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xs {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xs {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xs {
    padding: 5px !important; }
  .xs-down-p-xs-child > * {
    padding: 5px !important; }
  .xs-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xs {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xs {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xs {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xs {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-sm {
    margin: 15px !important; }
  .xs-down-m-sm-child > * {
    margin: 15px !important; }
  .xs-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-sm {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-sm {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-sm {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-sm {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-sm {
    padding: 15px !important; }
  .xs-down-p-sm-child > * {
    padding: 15px !important; }
  .xs-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-sm {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-sm {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-sm {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-sm {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-md {
    margin: 30px !important; }
  .xs-down-m-md-child > * {
    margin: 30px !important; }
  .xs-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-md {
    margin-top: 30px !important; }
  .xs-down-mt-md-child > * {
    margin-top: 30px !important; }
  .xs-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-md {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-md {
    margin-left: 30px !important; }
  .xs-down-ml-md-child > * {
    margin-left: 30px !important; }
  .xs-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-md {
    margin-right: 30px !important; }
  .xs-down-mr-md-child > * {
    margin-right: 30px !important; }
  .xs-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-md {
    padding: 30px !important; }
  .xs-down-p-md-child > * {
    padding: 30px !important; }
  .xs-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-md {
    padding-top: 30px !important; }
  .xs-down-pt-md-child > * {
    padding-top: 30px !important; }
  .xs-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-md {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-md {
    padding-left: 30px !important; }
  .xs-down-pl-md-child > * {
    padding-left: 30px !important; }
  .xs-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-md {
    padding-right: 30px !important; }
  .xs-down-pr-md-child > * {
    padding-right: 30px !important; }
  .xs-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-lg {
    margin: 50px !important; }
  .xs-down-m-lg-child > * {
    margin: 50px !important; }
  .xs-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-lg {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-lg {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-lg {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-lg {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-lg {
    padding: 50px !important; }
  .xs-down-p-lg-child > * {
    padding: 50px !important; }
  .xs-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-lg {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-lg {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-lg {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-lg {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xl {
    margin: 70px !important; }
  .xs-down-m-xl-child > * {
    margin: 70px !important; }
  .xs-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xl {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xl {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xl {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xl {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xl {
    padding: 70px !important; }
  .xs-down-p-xl-child > * {
    padding: 70px !important; }
  .xs-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xl {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xl {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xl {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xl {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xxl {
    margin: 140px !important; }
  .xs-down-m-xxl-child > * {
    margin: 140px !important; }
  .xs-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xxl {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xxl {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xxl {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xxl {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xxl {
    padding: 140px !important; }
  .xs-down-p-xxl-child > * {
    padding: 140px !important; }
  .xs-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xxl {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xxl {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xxl {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xxl {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

.xs-up-m-0 {
  margin: 0 !important; }

.xs-up-m-0-child > * {
  margin: 0 !important; }

.xs-up-m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.xs-up-mt-0 {
  margin-top: 0 !important; }

.xs-up-mt-0-child > * {
  margin-top: 0 !important; }

.xs-up-mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.xs-up-mb-0 {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child > * {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.xs-up-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-ml-0 {
  margin-left: 0 !important; }

.xs-up-ml-0-child > * {
  margin-left: 0 !important; }

.xs-up-ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.xs-up-mr-0 {
  margin-right: 0 !important; }

.xs-up-mr-0-child > * {
  margin-right: 0 !important; }

.xs-up-mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.xs-up-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-p-0 {
  padding: 0 !important; }

.xs-up-p-0-child > * {
  padding: 0 !important; }

.xs-up-p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.xs-up-pt-0 {
  padding-top: 0 !important; }

.xs-up-pt-0-child > * {
  padding-top: 0 !important; }

.xs-up-pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.xs-up-pb-0 {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child > * {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.xs-up-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-pl-0 {
  padding-left: 0 !important; }

.xs-up-pl-0-child > * {
  padding-left: 0 !important; }

.xs-up-pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.xs-up-pr-0 {
  padding-right: 0 !important; }

.xs-up-pr-0-child > * {
  padding-right: 0 !important; }

.xs-up-pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.xs-up-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-m-xs {
  margin: 5px !important; }

.xs-up-m-xs-child > * {
  margin: 5px !important; }

.xs-up-m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.xs-up-mt-xs {
  margin-top: 5px !important; }

.xs-up-mt-xs-child > * {
  margin-top: 5px !important; }

.xs-up-mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.xs-up-mb-xs {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child > * {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.xs-up-my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-ml-xs {
  margin-left: 5px !important; }

.xs-up-ml-xs-child > * {
  margin-left: 5px !important; }

.xs-up-ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.xs-up-mr-xs {
  margin-right: 5px !important; }

.xs-up-mr-xs-child > * {
  margin-right: 5px !important; }

.xs-up-mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.xs-up-mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-p-xs {
  padding: 5px !important; }

.xs-up-p-xs-child > * {
  padding: 5px !important; }

.xs-up-p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.xs-up-pt-xs {
  padding-top: 5px !important; }

.xs-up-pt-xs-child > * {
  padding-top: 5px !important; }

.xs-up-pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.xs-up-pb-xs {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child > * {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.xs-up-py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-pl-xs {
  padding-left: 5px !important; }

.xs-up-pl-xs-child > * {
  padding-left: 5px !important; }

.xs-up-pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.xs-up-pr-xs {
  padding-right: 5px !important; }

.xs-up-pr-xs-child > * {
  padding-right: 5px !important; }

.xs-up-pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.xs-up-px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-m-sm {
  margin: 15px !important; }

.xs-up-m-sm-child > * {
  margin: 15px !important; }

.xs-up-m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.xs-up-mt-sm {
  margin-top: 15px !important; }

.xs-up-mt-sm-child > * {
  margin-top: 15px !important; }

.xs-up-mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.xs-up-mb-sm {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child > * {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.xs-up-my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-ml-sm {
  margin-left: 15px !important; }

.xs-up-ml-sm-child > * {
  margin-left: 15px !important; }

.xs-up-ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.xs-up-mr-sm {
  margin-right: 15px !important; }

.xs-up-mr-sm-child > * {
  margin-right: 15px !important; }

.xs-up-mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.xs-up-mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-p-sm {
  padding: 15px !important; }

.xs-up-p-sm-child > * {
  padding: 15px !important; }

.xs-up-p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.xs-up-pt-sm {
  padding-top: 15px !important; }

.xs-up-pt-sm-child > * {
  padding-top: 15px !important; }

.xs-up-pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.xs-up-pb-sm {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child > * {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.xs-up-py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-pl-sm {
  padding-left: 15px !important; }

.xs-up-pl-sm-child > * {
  padding-left: 15px !important; }

.xs-up-pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.xs-up-pr-sm {
  padding-right: 15px !important; }

.xs-up-pr-sm-child > * {
  padding-right: 15px !important; }

.xs-up-pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.xs-up-px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-m-md {
  margin: 30px !important; }

.xs-up-m-md-child > * {
  margin: 30px !important; }

.xs-up-m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.xs-up-mt-md {
  margin-top: 30px !important; }

.xs-up-mt-md-child > * {
  margin-top: 30px !important; }

.xs-up-mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.xs-up-mb-md {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child > * {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.xs-up-my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-ml-md {
  margin-left: 30px !important; }

.xs-up-ml-md-child > * {
  margin-left: 30px !important; }

.xs-up-ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.xs-up-mr-md {
  margin-right: 30px !important; }

.xs-up-mr-md-child > * {
  margin-right: 30px !important; }

.xs-up-mr-md-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.xs-up-mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-p-md {
  padding: 30px !important; }

.xs-up-p-md-child > * {
  padding: 30px !important; }

.xs-up-p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.xs-up-pt-md {
  padding-top: 30px !important; }

.xs-up-pt-md-child > * {
  padding-top: 30px !important; }

.xs-up-pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.xs-up-pb-md {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child > * {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.xs-up-py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-pl-md {
  padding-left: 30px !important; }

.xs-up-pl-md-child > * {
  padding-left: 30px !important; }

.xs-up-pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.xs-up-pr-md {
  padding-right: 30px !important; }

.xs-up-pr-md-child > * {
  padding-right: 30px !important; }

.xs-up-pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.xs-up-px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-m-lg {
  margin: 50px !important; }

.xs-up-m-lg-child > * {
  margin: 50px !important; }

.xs-up-m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.xs-up-mt-lg {
  margin-top: 50px !important; }

.xs-up-mt-lg-child > * {
  margin-top: 50px !important; }

.xs-up-mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.xs-up-mb-lg {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child > * {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.xs-up-my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-ml-lg {
  margin-left: 50px !important; }

.xs-up-ml-lg-child > * {
  margin-left: 50px !important; }

.xs-up-ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.xs-up-mr-lg {
  margin-right: 50px !important; }

.xs-up-mr-lg-child > * {
  margin-right: 50px !important; }

.xs-up-mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.xs-up-mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-p-lg {
  padding: 50px !important; }

.xs-up-p-lg-child > * {
  padding: 50px !important; }

.xs-up-p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.xs-up-pt-lg {
  padding-top: 50px !important; }

.xs-up-pt-lg-child > * {
  padding-top: 50px !important; }

.xs-up-pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.xs-up-pb-lg {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child > * {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.xs-up-py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-pl-lg {
  padding-left: 50px !important; }

.xs-up-pl-lg-child > * {
  padding-left: 50px !important; }

.xs-up-pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.xs-up-pr-lg {
  padding-right: 50px !important; }

.xs-up-pr-lg-child > * {
  padding-right: 50px !important; }

.xs-up-pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.xs-up-px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-m-xl {
  margin: 70px !important; }

.xs-up-m-xl-child > * {
  margin: 70px !important; }

.xs-up-m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.xs-up-mt-xl {
  margin-top: 70px !important; }

.xs-up-mt-xl-child > * {
  margin-top: 70px !important; }

.xs-up-mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.xs-up-mb-xl {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child > * {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.xs-up-my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-ml-xl {
  margin-left: 70px !important; }

.xs-up-ml-xl-child > * {
  margin-left: 70px !important; }

.xs-up-ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.xs-up-mr-xl {
  margin-right: 70px !important; }

.xs-up-mr-xl-child > * {
  margin-right: 70px !important; }

.xs-up-mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.xs-up-mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-p-xl {
  padding: 70px !important; }

.xs-up-p-xl-child > * {
  padding: 70px !important; }

.xs-up-p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.xs-up-pt-xl {
  padding-top: 70px !important; }

.xs-up-pt-xl-child > * {
  padding-top: 70px !important; }

.xs-up-pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.xs-up-pb-xl {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child > * {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.xs-up-py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-pl-xl {
  padding-left: 70px !important; }

.xs-up-pl-xl-child > * {
  padding-left: 70px !important; }

.xs-up-pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.xs-up-pr-xl {
  padding-right: 70px !important; }

.xs-up-pr-xl-child > * {
  padding-right: 70px !important; }

.xs-up-pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.xs-up-px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-m-xxl {
  margin: 140px !important; }

.xs-up-m-xxl-child > * {
  margin: 140px !important; }

.xs-up-m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.xs-up-mt-xxl {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child > * {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.xs-up-mb-xxl {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child > * {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.xs-up-my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-ml-xxl {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child > * {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.xs-up-mr-xxl {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child > * {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.xs-up-mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-p-xxl {
  padding: 140px !important; }

.xs-up-p-xxl-child > * {
  padding: 140px !important; }

.xs-up-p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.xs-up-pt-xxl {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child > * {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.xs-up-pb-xxl {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child > * {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.xs-up-py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-pl-xxl {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child > * {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.xs-up-pr-xxl {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child > * {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.xs-up-px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (min-width: 576px) {
  .sm-up-m-0 {
    margin: 0 !important; }
  .sm-up-m-0-child > * {
    margin: 0 !important; }
  .sm-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mt-0 {
    margin-top: 0 !important; }
  .sm-up-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mb-0 {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-ml-0 {
    margin-left: 0 !important; }
  .sm-up-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mr-0 {
    margin-right: 0 !important; }
  .sm-up-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-p-0 {
    padding: 0 !important; }
  .sm-up-p-0-child > * {
    padding: 0 !important; }
  .sm-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pt-0 {
    padding-top: 0 !important; }
  .sm-up-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pb-0 {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pl-0 {
    padding-left: 0 !important; }
  .sm-up-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pr-0 {
    padding-right: 0 !important; }
  .sm-up-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-m-xs {
    margin: 5px !important; }
  .sm-up-m-xs-child > * {
    margin: 5px !important; }
  .sm-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xs {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xs {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xs {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xs {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xs {
    padding: 5px !important; }
  .sm-up-p-xs-child > * {
    padding: 5px !important; }
  .sm-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xs {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xs {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xs {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xs {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-m-sm {
    margin: 15px !important; }
  .sm-up-m-sm-child > * {
    margin: 15px !important; }
  .sm-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-sm {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-sm {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-sm {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-sm {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-p-sm {
    padding: 15px !important; }
  .sm-up-p-sm-child > * {
    padding: 15px !important; }
  .sm-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-sm {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-sm {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-sm {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-sm {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-m-md {
    margin: 30px !important; }
  .sm-up-m-md-child > * {
    margin: 30px !important; }
  .sm-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-md {
    margin-top: 30px !important; }
  .sm-up-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-md {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-md {
    margin-left: 30px !important; }
  .sm-up-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-md {
    margin-right: 30px !important; }
  .sm-up-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-p-md {
    padding: 30px !important; }
  .sm-up-p-md-child > * {
    padding: 30px !important; }
  .sm-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-md {
    padding-top: 30px !important; }
  .sm-up-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-md {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-md {
    padding-left: 30px !important; }
  .sm-up-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-md {
    padding-right: 30px !important; }
  .sm-up-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-m-lg {
    margin: 50px !important; }
  .sm-up-m-lg-child > * {
    margin: 50px !important; }
  .sm-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-lg {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-lg {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-lg {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-lg {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-p-lg {
    padding: 50px !important; }
  .sm-up-p-lg-child > * {
    padding: 50px !important; }
  .sm-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-lg {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-lg {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-lg {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-lg {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xl {
    margin: 70px !important; }
  .sm-up-m-xl-child > * {
    margin: 70px !important; }
  .sm-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xl {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xl {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xl {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xl {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xl {
    padding: 70px !important; }
  .sm-up-p-xl-child > * {
    padding: 70px !important; }
  .sm-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xl {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xl {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xl {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xl {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xxl {
    margin: 140px !important; }
  .sm-up-m-xxl-child > * {
    margin: 140px !important; }
  .sm-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xxl {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xxl {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xxl {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xxl {
    padding: 140px !important; }
  .sm-up-p-xxl-child > * {
    padding: 140px !important; }
  .sm-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xxl {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xxl {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xxl {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-m-0 {
    margin: 0 !important; }
  .md-up-m-0-child > * {
    margin: 0 !important; }
  .md-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mt-0 {
    margin-top: 0 !important; }
  .md-up-mt-0-child > * {
    margin-top: 0 !important; }
  .md-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mb-0 {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-ml-0 {
    margin-left: 0 !important; }
  .md-up-ml-0-child > * {
    margin-left: 0 !important; }
  .md-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mr-0 {
    margin-right: 0 !important; }
  .md-up-mr-0-child > * {
    margin-right: 0 !important; }
  .md-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-p-0 {
    padding: 0 !important; }
  .md-up-p-0-child > * {
    padding: 0 !important; }
  .md-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pt-0 {
    padding-top: 0 !important; }
  .md-up-pt-0-child > * {
    padding-top: 0 !important; }
  .md-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pb-0 {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pl-0 {
    padding-left: 0 !important; }
  .md-up-pl-0-child > * {
    padding-left: 0 !important; }
  .md-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pr-0 {
    padding-right: 0 !important; }
  .md-up-pr-0-child > * {
    padding-right: 0 !important; }
  .md-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-m-xs {
    margin: 5px !important; }
  .md-up-m-xs-child > * {
    margin: 5px !important; }
  .md-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xs {
    margin-top: 5px !important; }
  .md-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xs {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xs {
    margin-left: 5px !important; }
  .md-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xs {
    margin-right: 5px !important; }
  .md-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-p-xs {
    padding: 5px !important; }
  .md-up-p-xs-child > * {
    padding: 5px !important; }
  .md-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xs {
    padding-top: 5px !important; }
  .md-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xs {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xs {
    padding-left: 5px !important; }
  .md-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xs {
    padding-right: 5px !important; }
  .md-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-m-sm {
    margin: 15px !important; }
  .md-up-m-sm-child > * {
    margin: 15px !important; }
  .md-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mt-sm {
    margin-top: 15px !important; }
  .md-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mb-sm {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-ml-sm {
    margin-left: 15px !important; }
  .md-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mr-sm {
    margin-right: 15px !important; }
  .md-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-p-sm {
    padding: 15px !important; }
  .md-up-p-sm-child > * {
    padding: 15px !important; }
  .md-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pt-sm {
    padding-top: 15px !important; }
  .md-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pb-sm {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pl-sm {
    padding-left: 15px !important; }
  .md-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pr-sm {
    padding-right: 15px !important; }
  .md-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-m-md {
    margin: 30px !important; }
  .md-up-m-md-child > * {
    margin: 30px !important; }
  .md-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mt-md {
    margin-top: 30px !important; }
  .md-up-mt-md-child > * {
    margin-top: 30px !important; }
  .md-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mb-md {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-ml-md {
    margin-left: 30px !important; }
  .md-up-ml-md-child > * {
    margin-left: 30px !important; }
  .md-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mr-md {
    margin-right: 30px !important; }
  .md-up-mr-md-child > * {
    margin-right: 30px !important; }
  .md-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-p-md {
    padding: 30px !important; }
  .md-up-p-md-child > * {
    padding: 30px !important; }
  .md-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pt-md {
    padding-top: 30px !important; }
  .md-up-pt-md-child > * {
    padding-top: 30px !important; }
  .md-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pb-md {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pl-md {
    padding-left: 30px !important; }
  .md-up-pl-md-child > * {
    padding-left: 30px !important; }
  .md-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pr-md {
    padding-right: 30px !important; }
  .md-up-pr-md-child > * {
    padding-right: 30px !important; }
  .md-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-m-lg {
    margin: 50px !important; }
  .md-up-m-lg-child > * {
    margin: 50px !important; }
  .md-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mt-lg {
    margin-top: 50px !important; }
  .md-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mb-lg {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-ml-lg {
    margin-left: 50px !important; }
  .md-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mr-lg {
    margin-right: 50px !important; }
  .md-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-p-lg {
    padding: 50px !important; }
  .md-up-p-lg-child > * {
    padding: 50px !important; }
  .md-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pt-lg {
    padding-top: 50px !important; }
  .md-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pb-lg {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pl-lg {
    padding-left: 50px !important; }
  .md-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pr-lg {
    padding-right: 50px !important; }
  .md-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-m-xl {
    margin: 70px !important; }
  .md-up-m-xl-child > * {
    margin: 70px !important; }
  .md-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xl {
    margin-top: 70px !important; }
  .md-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xl {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xl {
    margin-left: 70px !important; }
  .md-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xl {
    margin-right: 70px !important; }
  .md-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-p-xl {
    padding: 70px !important; }
  .md-up-p-xl-child > * {
    padding: 70px !important; }
  .md-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xl {
    padding-top: 70px !important; }
  .md-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xl {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xl {
    padding-left: 70px !important; }
  .md-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xl {
    padding-right: 70px !important; }
  .md-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-m-xxl {
    margin: 140px !important; }
  .md-up-m-xxl-child > * {
    margin: 140px !important; }
  .md-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xxl {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xxl {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xxl {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xxl {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-p-xxl {
    padding: 140px !important; }
  .md-up-p-xxl-child > * {
    padding: 140px !important; }
  .md-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xxl {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xxl {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xxl {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xxl {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-m-0 {
    margin: 0 !important; }
  .lg-up-m-0-child > * {
    margin: 0 !important; }
  .lg-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mt-0 {
    margin-top: 0 !important; }
  .lg-up-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mb-0 {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-ml-0 {
    margin-left: 0 !important; }
  .lg-up-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mr-0 {
    margin-right: 0 !important; }
  .lg-up-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-p-0 {
    padding: 0 !important; }
  .lg-up-p-0-child > * {
    padding: 0 !important; }
  .lg-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pt-0 {
    padding-top: 0 !important; }
  .lg-up-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pb-0 {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pl-0 {
    padding-left: 0 !important; }
  .lg-up-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-pr-0 {
    padding-right: 0 !important; }
  .lg-up-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 992px) {
  .lg-up-m-xs {
    margin: 5px !important; }
  .lg-up-m-xs-child > * {
    margin: 5px !important; }
  .lg-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xs {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xs {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xs {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xs {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xs {
    padding: 5px !important; }
  .lg-up-p-xs-child > * {
    padding: 5px !important; }
  .lg-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xs {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xs {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xs {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xs {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 992px) {
  .lg-up-m-sm {
    margin: 15px !important; }
  .lg-up-m-sm-child > * {
    margin: 15px !important; }
  .lg-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-sm {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-sm {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-sm {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-sm {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-p-sm {
    padding: 15px !important; }
  .lg-up-p-sm-child > * {
    padding: 15px !important; }
  .lg-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-sm {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-sm {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-sm {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-sm {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 992px) {
  .lg-up-m-md {
    margin: 30px !important; }
  .lg-up-m-md-child > * {
    margin: 30px !important; }
  .lg-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-md {
    margin-top: 30px !important; }
  .lg-up-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-md {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-md {
    margin-left: 30px !important; }
  .lg-up-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-md {
    margin-right: 30px !important; }
  .lg-up-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-p-md {
    padding: 30px !important; }
  .lg-up-p-md-child > * {
    padding: 30px !important; }
  .lg-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-md {
    padding-top: 30px !important; }
  .lg-up-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-md {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-md {
    padding-left: 30px !important; }
  .lg-up-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-md {
    padding-right: 30px !important; }
  .lg-up-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 992px) {
  .lg-up-m-lg {
    margin: 50px !important; }
  .lg-up-m-lg-child > * {
    margin: 50px !important; }
  .lg-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-lg {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-lg {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-lg {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-lg {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-p-lg {
    padding: 50px !important; }
  .lg-up-p-lg-child > * {
    padding: 50px !important; }
  .lg-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-lg {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-lg {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-lg {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-lg {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xl {
    margin: 70px !important; }
  .lg-up-m-xl-child > * {
    margin: 70px !important; }
  .lg-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xl {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xl {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xl {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xl {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xl {
    padding: 70px !important; }
  .lg-up-p-xl-child > * {
    padding: 70px !important; }
  .lg-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xl {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xl {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xl {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xl {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 992px) {
  .lg-up-m-xxl {
    margin: 140px !important; }
  .lg-up-m-xxl-child > * {
    margin: 140px !important; }
  .lg-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mt-xxl {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-ml-xxl {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mr-xxl {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-p-xxl {
    padding: 140px !important; }
  .lg-up-p-xxl-child > * {
    padding: 140px !important; }
  .lg-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pt-xxl {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pl-xxl {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-pr-xxl {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 992px) {
  .lg-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-0 {
    margin: 0 !important; }
  .xl-up-m-0-child > * {
    margin: 0 !important; }
  .xl-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-0 {
    margin-top: 0 !important; }
  .xl-up-mt-0-child > * {
    margin-top: 0 !important; }
  .xl-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-0 {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-0 {
    margin-left: 0 !important; }
  .xl-up-ml-0-child > * {
    margin-left: 0 !important; }
  .xl-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-0 {
    margin-right: 0 !important; }
  .xl-up-mr-0-child > * {
    margin-right: 0 !important; }
  .xl-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-p-0 {
    padding: 0 !important; }
  .xl-up-p-0-child > * {
    padding: 0 !important; }
  .xl-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-0 {
    padding-top: 0 !important; }
  .xl-up-pt-0-child > * {
    padding-top: 0 !important; }
  .xl-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-0 {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-0 {
    padding-left: 0 !important; }
  .xl-up-pl-0-child > * {
    padding-left: 0 !important; }
  .xl-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-0 {
    padding-right: 0 !important; }
  .xl-up-pr-0-child > * {
    padding-right: 0 !important; }
  .xl-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xs {
    margin: 5px !important; }
  .xl-up-m-xs-child > * {
    margin: 5px !important; }
  .xl-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xs {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xs {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xs {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xs {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xs {
    padding: 5px !important; }
  .xl-up-p-xs-child > * {
    padding: 5px !important; }
  .xl-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xs {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xs {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xs {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xs {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-sm {
    margin: 15px !important; }
  .xl-up-m-sm-child > * {
    margin: 15px !important; }
  .xl-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-sm {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-sm {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-sm {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-sm {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-sm {
    padding: 15px !important; }
  .xl-up-p-sm-child > * {
    padding: 15px !important; }
  .xl-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-sm {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-sm {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-sm {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-sm {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-md {
    margin: 30px !important; }
  .xl-up-m-md-child > * {
    margin: 30px !important; }
  .xl-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-md {
    margin-top: 30px !important; }
  .xl-up-mt-md-child > * {
    margin-top: 30px !important; }
  .xl-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-md {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-md {
    margin-left: 30px !important; }
  .xl-up-ml-md-child > * {
    margin-left: 30px !important; }
  .xl-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-md {
    margin-right: 30px !important; }
  .xl-up-mr-md-child > * {
    margin-right: 30px !important; }
  .xl-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-md {
    padding: 30px !important; }
  .xl-up-p-md-child > * {
    padding: 30px !important; }
  .xl-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-md {
    padding-top: 30px !important; }
  .xl-up-pt-md-child > * {
    padding-top: 30px !important; }
  .xl-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-md {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-md {
    padding-left: 30px !important; }
  .xl-up-pl-md-child > * {
    padding-left: 30px !important; }
  .xl-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-md {
    padding-right: 30px !important; }
  .xl-up-pr-md-child > * {
    padding-right: 30px !important; }
  .xl-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-lg {
    margin: 50px !important; }
  .xl-up-m-lg-child > * {
    margin: 50px !important; }
  .xl-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-lg {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-lg {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-lg {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-lg {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-lg {
    padding: 50px !important; }
  .xl-up-p-lg-child > * {
    padding: 50px !important; }
  .xl-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-lg {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-lg {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-lg {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-lg {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xl {
    margin: 70px !important; }
  .xl-up-m-xl-child > * {
    margin: 70px !important; }
  .xl-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xl {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xl {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xl {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xl {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xl {
    padding: 70px !important; }
  .xl-up-p-xl-child > * {
    padding: 70px !important; }
  .xl-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xl {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xl {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xl {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xl {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 1200px) {
  .xl-up-m-xxl {
    margin: 140px !important; }
  .xl-up-m-xxl-child > * {
    margin: 140px !important; }
  .xl-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mt-xxl {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mb-xxl {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-ml-xxl {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mr-xxl {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-p-xxl {
    padding: 140px !important; }
  .xl-up-p-xxl-child > * {
    padding: 140px !important; }
  .xl-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pt-xxl {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pb-xxl {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pl-xxl {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-pr-xxl {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 1200px) {
  .xl-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/
@font-face {
  font-family: "ITC Avant Garde Gothic Std";
  src: url("../fonts/avant-garde/AvantGardeLT-Book.eot");
  src: url("../fonts/avant-garde/AvantGardeLT-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/avant-garde/AvantGardeLT-Book.woff2") format("woff2"), url("../fonts/avant-garde/AvantGardeLT-Book.woff") format("woff"), url("../fonts/avant-garde/AvantGardeLT-Book.ttf") format("truetype"), url("../fonts/avant-garde/AvantGardeLT-Book.svg#AvantGardeLT-Book") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "ITC Avant Garde Gothic Std";
  src: url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.eot");
  src: url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.eot?#iefix") format("embedded-opentype"), url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.woff2") format("woff2"), url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.woff") format("woff"), url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.ttf") format("truetype"), url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.svg#ITCAvantGardeStd-XLt") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "ITC Avant Garde Gothic Std";
  src: url("../fonts/avant-garde/ITCAvantGardeStd-XLt.woff") format("woff");
  font-weight: 225;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/avenir-next/AvenirNextLTW01RegularRegular.eot");
  src: url("../fonts/avenir-next/AvenirNextLTW01RegularRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNextLTW01RegularRegular.woff2") format("woff2"), url("../fonts/avenir-next/AvenirNextLTW01RegularRegular.woff") format("woff"), url("../fonts/avenir-next/AvenirNextLTW01RegularRegular.svg#AvenirNextLTPro") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/avenir-next/AvenirNextLTPro-Bold.eot");
  src: url("../fonts/avenir-next/AvenirNextLTPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-next/AvenirNextLTPro-Bold.woff2") format("woff2"), url("../fonts/avenir-next/AvenirNextLTPro-Bold.woff") format("woff"), url("../fonts/avenir-next/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/helvetica/Helvetica.eot");
  src: url("../fonts/helvetica/Helvetica.eot?#iefix") format("embedded-opentype"), url("../fonts/helvetica/Helvetica.woff2") format("woff2"), url("../fonts/helvetica/Helvetica.woff") format("woff"), url("../fonts/helvetica/Helvetica.svg#Helvetica-Bold") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Thin.eot");
  src: url("../fonts/poppins/Poppins-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/Poppins-Thin.woff2") format("woff2"), url("../fonts/poppins/Poppins-Thin.woff") format("woff"), url("../fonts/poppins/Poppins-Thin.svg#Poppins-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-ExtraLight.eot");
  src: url("../fonts/poppins/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/Poppins-ExtraLight.woff2") format("woff2"), url("../fonts/poppins/Poppins-ExtraLight.woff") format("woff"), url("../fonts/poppins/Poppins-ExtraLight.svg#Poppins-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Light.eot");
  src: url("../fonts/poppins/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/Poppins-Light.woff2") format("woff2"), url("../fonts/poppins/Poppins-Light.woff") format("woff"), url("../fonts/poppins/Poppins-Light.svg#Poppins-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Medium.eot");
  src: url("../fonts/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/Poppins-Medium.woff2") format("woff2"), url("../fonts/poppins/Poppins-Medium.woff") format("woff"), url("../fonts/poppins/Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-SemiBold.eot");
  src: url("../fonts/poppins/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/poppins/Poppins-SemiBold.woff") format("woff"), url("../fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: "Inter", sans-serif;
  color: #3c4135;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 200;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden; }

h1 {
  font-family: "ITC Avant Garde Gothic Std";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 96px;
  text-transform: initial; }
  @media (max-width: 1399.98px) {
    h1 {
      font-size: 55px;
      line-height: 63px; } }
  @media (max-width: 1199.98px) {
    h1 {
      font-size: 46px;
      line-height: 50px; } }
  @media (max-width: 991.98px) {
    h1 {
      font-size: 40px;
      line-height: 44px; } }
  @media (max-width: 767.98px) {
    h1 {
      font-size: 34px;
      line-height: 42px; } }

h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif; }

h2 {
  font-family: "ITC Avant Garde Gothic Std";
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #4b6e83; }
  @media (max-width: 1399.98px) {
    h2 {
      font-size: 44px;
      line-height: 52px; } }
  @media (max-width: 991.98px) {
    h2 {
      font-size: 28px;
      line-height: 32px; } }

button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important; }

.clearfix {
  clear: both;
  width: 100%; }

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none; }

img {
  max-width: inherit; }

p sup {
  top: -5px;
  padding-left: 1px;
  font-size: 12px;
  font-weight: initial; }

sup {
  top: -0.5em;
  font-size: 29px;
  font-weight: bold; }

.z1 {
  z-index: 1; }

img,
button {
  display: inline-block; }

a {
  color: #000000; }
  a:hover {
    color: #000000; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style-type: none; }

.clearfix {
  clear: both;
  width: 100%;
  display: block; }

.m-a {
  margin: auto;
  display: block; }

.d-t {
  display: table !important; }

.td-0 {
  text-decoration: none; }

.txt-c {
  text-align: center !important; }

.txt-l {
  text-align: left !important; }

.txt-r {
  text-align: right !important; }

.w100 {
  width: 100%; }

.logo-c {
  display: none; }

.rel {
  position: relative; }

.disp-desk {
  display: block !important; }
  @media (max-width: 991.98px) {
    .disp-desk {
      display: none !important; } }

.disp-mob {
  display: none !important; }
  @media (max-width: 991.98px) {
    .disp-mob {
      display: block !important; } }

.disp-desk-lg {
  display: block !important; }
  @media (max-width: 1199.98px) {
    .disp-desk-lg {
      display: none !important; } }

.disp-mob-lg {
  display: none !important; }
  @media (max-width: 1199.98px) {
    .disp-mob-lg {
      display: block !important; } }

.dflex {
  display: flex !important;
  vertical-align: middle;
  align-items: center; }

.block-img {
  display: block;
  overflow: hidden; }
  .block-img .bg-img, .block-img .bg-img-square, .block-img .bg-img-almost-square, .block-img .bg-img-landscape, .block-img .bg-img-landscape-sm, .block-img .bg-img-portrait {
    display: block;
    background-size: cover !important;
    transform: scale(1);
    transition: all ease 0.3s;
    background: initial; }
  .block-img:hover .bg-img, .block-img:hover .bg-img-square, .block-img:hover .bg-img-almost-square, .block-img:hover .bg-img-landscape, .block-img:hover .bg-img-landscape-sm, .block-img:hover .bg-img-portrait {
    transform: scale(1.04);
    transition: all ease 0.3s; }

.btn, .btn-black, .btn-white, .btn-xs, .btn-sm, .btn-md, .btn-lg {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  border: 1px solid rgba(40, 72, 90, 0.5);
  padding: 22px 50px;
  text-decoration: none;
  margin: auto;
  display: inline-block;
  text-align: center;
  min-width: 280px;
  color: #3c4135;
  cursor: pointer;
  box-shadow: inherit;
  animation: leave 0.4s forwards; }
  @media (max-width: 767.98px) {
    .btn, .btn-black, .btn-white, .btn-xs, .btn-sm, .btn-md, .btn-lg {
      padding: 22px 15px; } }
  .btn .btn-text, .btn-black .btn-text, .btn-white .btn-text, .btn-xs .btn-text, .btn-sm .btn-text, .btn-md .btn-text, .btn-lg .btn-text {
    margin: auto; }
  .btn svg, .btn-black svg, .btn-white svg, .btn-xs svg, .btn-sm svg, .btn-md svg, .btn-lg svg {
    margin-left: 15px;
    margin-top: -2px; }
    .btn svg path, .btn-black svg path, .btn-white svg path, .btn-xs svg path, .btn-sm svg path, .btn-md svg path, .btn-lg svg path {
      transition: all ease 0.4s; }
  .btn:hover, .btn-black:hover, .btn-white:hover, .btn-xs:hover, .btn-sm:hover, .btn-md:hover, .btn-lg:hover {
    animation: hover 0.4s forwards;
    color: white; }
    .btn:hover svg, .btn-black:hover svg, .btn-white:hover svg, .btn-xs:hover svg, .btn-sm:hover svg, .btn-md:hover svg, .btn-lg:hover svg {
      transition: all ease 0.4s; }
      .btn:hover svg path, .btn-black:hover svg path, .btn-white:hover svg path, .btn-xs:hover svg path, .btn-sm:hover svg path, .btn-md:hover svg path, .btn-lg:hover svg path {
        fill: white;
        transition: all ease 0.4s; }
  .btn-alt {
    animation: leave-alt 0.4s forwards;
    border: 1px solid white; }
    .btn-alt .btn-text {
      color: white; }
    .btn-alt:hover {
      animation: hover-alt 0.4s forwards; }
      .btn-alt:hover svg path {
        fill: #28485a; }
      .btn-alt:hover .btn-text {
        color: #28485a; }

@keyframes hover {
  from {
    box-shadow: inset 0 0 0 0.01px #28485a; }
  to {
    box-shadow: inset 40em 0 0 0.01px #28485a;
    color: #fff; } }

@keyframes leave {
  from {
    box-shadow: inset -40em 0 0 0.01px #28485a;
    color: #fff; }
  to {
    box-shadow: inset 0 0 0 0.01px #28485a; } }

@keyframes hover-alt {
  from {
    box-shadow: inset 0 0 0 0.01px white; }
  to {
    box-shadow: inset 40em 0 0 0.01px white;
    color: #28485a; } }

@keyframes leave-alt {
  from {
    box-shadow: inset -40em 0 0 0.01px white;
    color: #28485a; }
  to {
    box-shadow: inset 0 0 0 0.01px white; } }

.col-centered {
  margin: 0 auto; }

.mt-80 {
  margin-top: -80px; }

/*
|--------------------
|      HEADER
|--------------------
*/
body.menu-active {
  overflow: hidden; }

/*
|
| Header
|---------
*/
.link-menu, .lang-container {
  text-transform: uppercase;
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 400; }

.bandeau {
  color: #ffffff;
  padding: 11px 15px;
  font-size: 45px;
  line-height: 54px;
  width: 100%;
  display: block;
  z-index: 11;
  position: relative;
  text-align: center;
  top: 0; }
  @media (max-width: 991.98px) {
    .bandeau {
      font-size: 35px;
      line-height: 34px; } }
  @media (max-width: 767.98px) {
    .bandeau {
      font-size: 28px;
      line-height: 24px; } }
  .bandeau div {
    width: inherit; }
    .bandeau div img {
      height: 50px;
      margin-right: 10px; }
      @media (max-width: 767.98px) {
        .bandeau div img {
          height: 35px; } }
  .bandeau a {
    color: #28485a;
    background-color: #ffffff; }

#header {
  position: fixed; }
  #header.sticky-menu {
    background-color: #28485a;
    border-bottom: 1px solid #425c6b; }
    #header.sticky-menu .header-container {
      padding-top: 5px;
      padding-bottom: 5px;
      transition: all ease 0.3s; }
      #header.sticky-menu .header-container .item-logo img {
        height: 70px; }

#footer {
  position: absolute; }

#header,
#footer {
  width: 100%;
  z-index: 10; }
  #header .header-container,
  #footer .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
    transition: all ease 0.3s; }
    #header .header-container .item-logo,
    #footer .header-container .item-logo {
      z-index: 1;
      position: relative; }
      #header .header-container .item-logo img,
      #footer .header-container .item-logo img {
        height: 160px; }
        @media (max-width: 1199.98px) {
          #header .header-container .item-logo img,
          #footer .header-container .item-logo img {
            height: 80px; } }
  #header .btn-menu,
  #footer .btn-menu {
    display: none;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer; }
    #header .btn-menu > .item-burger,
    #footer .btn-menu > .item-burger {
      display: block;
      width: 25px;
      margin-top: -3px; }
      #header .btn-menu > .item-burger > span,
      #footer .btn-menu > .item-burger > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: white;
        border-radius: 2px; }
        #header .btn-menu > .item-burger > span:nth-child(2),
        #footer .btn-menu > .item-burger > span:nth-child(2) {
          margin: 6px 0; }
    @media (min-width: 1200px) {
      #header .btn-menu,
      #footer .btn-menu {
        display: none; } }

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #28485a;
  overflow: auto;
  opacity: 0;
  visibility: hidden; }
  #mobile-menu .item-container {
    margin-top: 110px; }
    #mobile-menu .item-container ul > li {
      margin-bottom: 5px;
      margin-left: 15px; }
      #mobile-menu .item-container ul > li a {
        text-transform: uppercase;
        text-decoration: none;
        color: white;
        font-size: 16px;
        font-weight: 500;
        -webkit-font-smoothing: antialiased; }

.item-nav {
  position: relative; }
  @media (max-width: 1199.98px) {
    .item-nav {
      display: none; } }
  .item-nav.item-nav-right .item-menu li {
    text-align: center; }
  .item-nav .item-menu {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .item-nav .item-menu li {
      display: table-cell;
      width: auto;
      position: relative;
      text-align: center; }
      .item-nav .item-menu li.current-menu-item .item-link:before, .item-nav .item-menu li.current_page_parent .item-link:before {
        content: "";
        height: 1px;
        width: 100%;
        transition: all ease 0.3s; }
      .item-nav .item-menu li .item-link {
        font-family: "Helvetica";
        font-style: normal;
        font-weight: 100;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        position: relative; }
        .item-nav .item-menu li .item-link:before {
          content: "";
          height: 1px;
          width: 0%;
          bottom: 0;
          position: absolute;
          background-color: white;
          transition: all ease 0.3s; }
        .item-nav .item-menu li .item-link:hover:before {
          content: "";
          height: 1px;
          width: 100%;
          transition: all ease 0.3s; }
      .item-nav .item-menu li .nav-drop {
        position: absolute;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        width: 100%;
        padding: 8px 0px 8px 0px;
        top: 26px;
        left: 0px;
        right: 0px;
        z-index: 9;
        display: none;
        background-color: rgba(255, 255, 255, 0.9); }
        .item-nav .item-menu li .nav-drop.active {
          top: 42px; }
        .item-nav .item-menu li .nav-drop .item0 {
          margin-top: -50px; }
        .item-nav .item-menu li .nav-drop li {
          z-index: 9;
          padding: 0 15px 10px 15px;
          width: 100%;
          display: inline-block;
          text-align: left; }
          .item-nav .item-menu li .nav-drop li a {
            font-family: "Avenir Next";
            z-index: 9;
            opacity: 0.8;
            font-size: 16px;
            font-weight: 400;
            line-height: initial;
            display: block;
            color: #000000;
            width: 100%;
            text-decoration: none;
            transition: all ease 0.2s; }
          .item-nav .item-menu li .nav-drop li:first-child {
            margin-top: 0px; }
          .item-nav .item-menu li .nav-drop li:hover {
            color: #000;
            opacity: 0.6;
            transition: all ease 0.2s; }
      .item-nav .item-menu li.menu-item-has-children:after {
        content: "";
        width: 14px;
        height: 9px;
        margin-left: 4px;
        display: inline-block;
        background-image: url("../img/icon/arrow-w.svg");
        background-size: contain;
        transition: all ease 0.6s; }
      .item-nav .item-menu li.menu-item-has-children:hover:after {
        content: "";
        transform: rotate(-180deg);
        transition: all ease 0.6s; }

.lang-container {
  position: relative;
  display: inline-flex;
  top: 1px;
  width: 60px; }
  .lang-container .lang-item {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    opacity: 0.5;
    text-decoration: none;
    cursor: pointer; }
    .lang-container .lang-item.active {
      opacity: 1;
      cursor: initial; }
    .lang-container .lang-item:first-child:after {
      content: "/";
      opacity: 1;
      padding-left: 5px;
      padding-right: 3px; }

/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  padding: 20px 0 15px 0;
  background-color: #28485a;
  z-index: 8; }
  #footer a {
    font-family: "Poppins";
    color: #ffffff;
    text-decoration: initial;
    font-weight: 400; }
  #footer .logo-footer {
    height: 160px;
    margin: auto;
    display: block; }
  #footer hr {
    opacity: 0.1;
    background-color: white; }
  #footer .header-container {
    text-align: left;
    padding-left: 0px; }
  #footer .copy .item {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    display: inline; }
    @media (max-width: 991.98px) {
      #footer .copy .item {
        display: block; }
        #footer .copy .item a {
          display: block; } }
    #footer .copy .item a,
    #footer .copy .item button {
      color: #ffffff;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      transition: all ease 0.3s;
      text-decoration: none; }
      #footer .copy .item a:hover,
      #footer .copy .item button:hover {
        opacity: 0.6;
        transition: all ease 0.3s; }
  @media (max-width: 991.98px) {
    #footer .copy {
      text-align: center; } }
  #footer .copy.txt-r {
    text-align: right; }
    @media (max-width: 991.98px) {
      #footer .copy.txt-r {
        text-align: center; } }
  #footer .item-menu .copy {
    width: 120px; }
    #footer .item-menu .copy .linkedin {
      background-image: url("../img/icon/linkedin.svg");
      height: 15px;
      width: 15px;
      display: block;
      float: right; }
  #footer .menu-item-has-children .nav-drop {
    top: initial;
    bottom: 0; }
    #footer .menu-item-has-children .nav-drop a {
      color: #28485a;
      font-weight: 400; }
  #footer .ft-bottom {
    font-family: "Poppins";
    font-weight: 300; }
    #footer .ft-bottom .copy a,
    #footer .ft-bottom .copy button {
      font-family: "Poppins";
      font-weight: 300; }
      @media (max-width: 1399.98px) {
        #footer .ft-bottom .copy a,
        #footer .ft-bottom .copy button {
          font-size: 13px;
          line-height: 23px; } }
      @media (max-width: 991.98px) {
        #footer .ft-bottom .copy a,
        #footer .ft-bottom .copy button {
          font-size: 16px;
          line-height: 28px; } }
    @media (max-width: 1199.98px) {
      #footer .ft-bottom .copy.c-left, #footer .ft-bottom .copy.c-right {
        text-align: center !important; } }
  #footer .ot-sdk-show-settings {
    color: #ffffff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    transition: all ease 0.3s;
    text-decoration: none; }

/*
|--------------------
|      CONTENT
|--------------------
*/
/*
|
| Banner
|---------
*/
.banner {
  min-height: 920px;
  color: white;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-color: #28485a;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: relative; }
  @media (max-width: 1199.98px) {
    .banner {
      min-height: 600px; } }
  .banner:before {
    content: "";
    min-height: 920px;
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5; }
    @media (max-width: 1199.98px) {
      .banner:before {
        min-height: 600px; } }
  .banner .suptitle {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase; }
  .banner .elem-banner {
    min-height: 920px; }
    @media (max-width: 1199.98px) {
      .banner .elem-banner {
        min-height: 600px; } }
    .banner .elem-banner .txt-c {
      font-family: "Poppins"; }
  .banner.banner-actus {
    min-height: 768px; }
    .banner.banner-actus:before {
      content: "";
      min-height: 768px; }
    .banner.banner-actus .elem-banner {
      min-height: 768px; }

.ariane {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #c4c4c4;
  margin-top: 210px;
  margin-left: 15px;
  position: absolute;
  z-index: 1;
  text-decoration: initial; }
  @media (max-width: 1199.98px) {
    .ariane {
      display: none; } }
  .ariane a,
  .ariane span {
    color: white;
    text-decoration: initial; }
  .ariane span {
    padding: 0 8px; }

/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
  cursor: pointer;
  /*
  * Card img
  */
  /*
  * Card content
  */
  /*
  * Hover state
  */ }
  .custom-card .custom-card-link {
    display: block;
    text-decoration: none;
    overflow: hidden;
    pointer-events: none;
    cursor: default; }
  .custom-card .card-img-container {
    position: relative;
    overflow: hidden;
    height: 280px; }
    @media (max-width: 991.98px) {
      .custom-card .card-img-container {
        height: 300px; } }
    .custom-card .card-img-container:after {
      content: "";
      display: block;
      padding-bottom: 100%; }
    .custom-card .card-img-container .card-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      will-change: transform;
      height: 280px; }
      @media (max-width: 991.98px) {
        .custom-card .card-img-container .card-img {
          height: 300px; } }
  .custom-card .card-content .card-date {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4b6e83; }
  .custom-card .card-content .card-title {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    text-align: left;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: black;
    text-transform: initial; }
  .custom-card .card-content .card-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #767a72; }
    .custom-card .card-content .card-content .more {
      text-decoration-line: underline;
      color: #28485a; }
  .custom-card:hover .card-img-container .card-img {
    transform: scale(1.05); }
  .custom-card.first-item .card-date {
    font-size: 20px;
    line-height: 30px; }
  .custom-card.first-item .card-title {
    font-weight: 400;
    font-family: "ITC Avant Garde Gothic Std"; }
  .custom-card.first-item .card-img-container {
    height: 660px; }
    @media (max-width: 991.98px) {
      .custom-card.first-item .card-img-container {
        height: 300px; } }
    .custom-card.first-item .card-img-container .card-img {
      height: 660px; }
      @media (max-width: 991.98px) {
        .custom-card.first-item .card-img-container .card-img {
          height: 300px; } }

/*
|
| Pagination
|---------------
*/
.pagination-container li {
  display: inline-block; }
  .pagination-container li a {
    transition: all 0.3s ease-out;
    padding: 0 8px; }
    .pagination-container li a:hover {
      color: #767a72; }
  .pagination-container li .current {
    padding: 0 8px;
    font-weight: 800;
    color: #767a72; }

/*
|
| CMS
|------
*/
.cms {
  color: #3c4135; }
  .cms span {
    display: inline; }
  .cms h1,
  .cms h2,
  .cms h3,
  .cms h4,
  .cms h5,
  .cms h6 {
    margin-bottom: 15px; }
  .cms h2 {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 44px;
    text-align: initial;
    color: #28485a;
    margin-top: 30px;
    margin-bottom: 10px; }
    @media (max-width: 1399.98px) {
      .cms h2 {
        font-size: 34px;
        line-height: 38px; } }
    @media (max-width: 1199.98px) {
      .cms h2 {
        font-size: 28px;
        line-height: 32px; } }
  .cms img {
    display: block;
    width: 100%;
    height: auto; }
  .cms strong {
    font-weight: 800; }
  .cms p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px; }
  .cms ul {
    padding-left: 0; }
    .cms ul li {
      position: relative;
      list-style-type: none;
      margin-bottom: 20px; }
      .cms ul li:before {
        content: ">";
        font-family: "Poppins";
        font-weight: 500;
        font-size: 18px;
        position: absolute;
        color: #28485a;
        left: -15px; }
  .cms ol {
    margin-bottom: 20px;
    padding-left: 20px; }
  .cms.cms-no-break p {
    margin-bottom: 0; }
  .cms blockquote {
    position: relative;
    width: 80%;
    margin-right: 0;
    margin-left: auto;
    padding: 130px 0;
    font-size: 30px;
    line-height: 1.5em;
    font-weight: 300; }
    @media (max-width: 991.98px) {
      .cms blockquote {
        margin: auto; } }
    .cms blockquote:before {
      content: "";
      position: absolute;
      background-image: url("../img/icon/quote-left.svg");
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      transform: translateX(-60px) translateY(-30px); }
    .cms blockquote:after {
      content: "";
      position: absolute;
      right: 0;
      background-image: url("../img/icon/quote-right.svg");
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      transform: translateX(0) translateY(-50px); }
      @media (max-width: 991.98px) {
        .cms blockquote:after {
          transform: translateX(60px) translateY(-50px); } }

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: 76px; }

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block; }
  .custom-loader.cl-center {
    margin-left: auto;
    margin-right: auto; }
  .custom-loader svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: #000000; }
    .custom-loader svg circle {
      stroke: #000000; }
  .custom-loader.ajax-loader {
    display: none; }

.gform_fields {
  margin: 0 !important; }

.gform_confirmation_wrapper {
  vertical-align: middle;
  align-items: center;
  margin: auto;
  display: grid; }

.gform_wrapper {
  font-family: "Poppins";
  font-size: 18px;
  color: white;
  letter-spacing: 0; }
  .gform_wrapper .gform_title,
  .gform_wrapper .gform_required_legend,
  .gform_wrapper .gform_validation_errors {
    display: none; }
  .gform_wrapper p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: white; }
  .gform_wrapper a {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: white; }
  .gform_wrapper .gfield {
    margin-bottom: 15px; }
  .gform_wrapper label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: white; }
  .gform_wrapper input[type="text"],
  .gform_wrapper input[type="email"],
  .gform_wrapper textarea,
  .gform_wrapper select {
    border: 1px solid white;
    box-sizing: border-box;
    padding: 13px !important;
    background: none;
    color: white; }
    .gform_wrapper input[type="text"]::placeholder,
    .gform_wrapper input[type="email"]::placeholder,
    .gform_wrapper textarea::placeholder,
    .gform_wrapper select::placeholder {
      opacity: 0.5;
      font-family: "Poppins";
      font-size: 18px;
      color: white;
      letter-spacing: 0; }
  .gform_wrapper select {
    font-family: "Avenir Next";
    font-size: 18px !important;
    color: white;
    letter-spacing: 0;
    border: 1px solid #d4d4d4; }
  .gform_wrapper textarea {
    height: 180px !important; }
  .gform_wrapper .ginput_container_consent {
    color: white;
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;
    font-weight: 400;
    position: relative; }
    .gform_wrapper .ginput_container_consent a {
      text-decoration: underline; }
    .gform_wrapper .ginput_container_consent .gfield_required {
      display: none; }
    .gform_wrapper .ginput_container_consent input[type="checkbox"] {
      position: absolute;
      cursor: pointer;
      border: none;
      left: 0;
      top: 5px;
      padding: 0;
      margin: 0; }
    .gform_wrapper .ginput_container_consent input[type="checkbox"]:before {
      content: "";
      display: block;
      position: absolute;
      width: 21px;
      height: 21px;
      top: 0;
      left: 0;
      border: 1px solid white;
      background-color: transparent; }
    .gform_wrapper .ginput_container_consent input[type="checkbox"]:checked:after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 3px;
      left: 3px;
      background: white; }
    .gform_wrapper .ginput_container_consent label {
      margin-top: 4px;
      margin-left: 35px; }
      @media (max-width: 1199.98px) {
        .gform_wrapper .ginput_container_consent label {
          padding-right: 0px; } }
  .gform_wrapper .gfield_required {
    color: white !important; }
  .gform_wrapper .gfield_description {
    font-family: "Avenir Next" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 14px !important;
    color: white; }
  .gform_wrapper .ginput_counter {
    color: white !important; }
  .gform_wrapper .gform_footer {
    position: relative; }
    .gform_wrapper .gform_footer .gform_button {
      font-family: "Avenir Next";
      background-color: #28485a;
      transition: all ease 0.3s;
      border: 1px solid rgba(255, 255, 255, 0.5);
      font-size: 18px;
      color: white;
      letter-spacing: 0;
      text-align: center;
      padding: 22px 90px;
      display: inline-block;
      width: auto;
      margin: auto;
      cursor: pointer;
      margin-top: 20px !important;
      margin-bottom: 40px !important; }
      .gform_wrapper .gform_footer .gform_button:hover {
        transition: all ease 0.3s;
        background-color: #28485a;
        color: white; }
    .gform_wrapper .gform_footer .gform_ajax_spinner {
      position: absolute;
      right: 0;
      left: 0;
      top: -45px;
      margin: auto; }
  .gform_wrapper .gform_wrapper .gfield_error .gfield_repeater_cell label,
  .gform_wrapper .gform_wrapper .gfield_error label,
  .gform_wrapper .gform_wrapper .gfield_error legend,
  .gform_wrapper .gform_wrapper .gfield_validation_message,
  .gform_wrapper .gform_wrapper .validation_message,
  .gform_wrapper .gform_wrapper [aria-invalid="true"] + label,
  .gform_wrapper .gform_wrapper label + [aria-invalid="true"] {
    color: #ff7758; }

.swiper-buttons {
  top: -70px;
  right: 30px;
  position: absolute; }
  .swiper-buttons .button-next,
  .swiper-buttons .button-prev {
    height: 54px;
    width: 54px;
    float: left;
    margin-left: 15px;
    position: relative;
    cursor: pointer;
    border: 1px solid #28485a;
    box-sizing: border-box;
    border-radius: 50%;
    transition: all ease 0.3s;
    background-color: #fff; }
    .swiper-buttons .button-next:before,
    .swiper-buttons .button-prev:before {
      content: "";
      background-image: url("../img/icon/arr.svg");
      background-size: cover;
      width: 18px;
      height: 8px;
      position: absolute;
      top: 21px;
      left: 17px; }
    .swiper-buttons .button-next.button-prev:before,
    .swiper-buttons .button-prev.button-prev:before {
      content: "";
      top: 24px;
      left: 17px;
      transform: rotate(180deg);
      background-image: url("../img/icon/arr.svg"); }
    .swiper-buttons .button-next.swiper-button-disabled,
    .swiper-buttons .button-prev.swiper-button-disabled {
      opacity: 0.2; }
  .swiper-buttons .sp-prod-button-prev {
    margin-left: 15px; }
    .swiper-buttons .sp-prod-button-prev:before {
      content: "";
      transform: rotate(180deg); }

.iziModal {
  max-width: 40% !important; }
  @media (max-width: 1199.98px) {
    .iziModal {
      max-width: 50% !important; } }
  @media (max-width: 991.98px) {
    .iziModal {
      max-width: 50% !important; } }
  @media (max-width: 767.98px) {
    .iziModal {
      max-width: 90% !important; } }
  .iziModal .icon-close {
    font-family: "NexaRegular";
    transform: rotate(45deg);
    color: white;
    text-shadow: 0px 0px 1px black;
    line-height: 35px;
    font-size: 50px;
    position: fixed;
    top: 0px;
    right: 7px;
    z-index: 1;
    cursor: pointer; }
    .iziModal .icon-close:hover {
      opacity: 0.7; }
    .iziModal .icon-close:focus {
      display: none; }
  .iziModal .modal-body .details {
    padding: 15px; }
  .iziModal .iziModal-content {
    padding: 0 0 10px 0 !important; }

.numbers {
  border-top: 1px solid white;
  border-bottom: 1px solid white; }

.chiffre .number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 100px;
  line-height: 100px;
  color: white; }
  @media (max-width: 1199.98px) {
    .chiffre .number {
      font-size: 70px;
      line-height: 80px; } }
  @media (max-width: 991.98px) {
    .chiffre .number {
      font-size: 60px;
      line-height: 85px; } }

.chiffre .unity {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 96px;
  color: white; }
  @media (max-width: 1199.98px) {
    .chiffre .unity {
      font-size: 44px;
      line-height: 96px; } }

.chiffre .texte {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: white; }

/*
|--------------------
|      BLOCKS
|--------------------
*/
.block-intro {
  margin-top: -150px;
  position: relative;
  overflow: hidden; }
  @media (max-width: 991.98px) {
    .block-intro {
      margin-top: 15px; } }
  .block-intro .block-img {
    height: 1020px; }
    @media (max-width: 991.98px) {
      .block-intro .block-img {
        height: 280px; } }
    .block-intro .block-img .bg-img, .block-intro .block-img .bg-img-square, .block-intro .block-img .bg-img-almost-square, .block-intro .block-img .bg-img-landscape, .block-intro .block-img .bg-img-landscape-sm, .block-intro .block-img .bg-img-portrait {
      height: 1020px; }
      @media (max-width: 991.98px) {
        .block-intro .block-img .bg-img, .block-intro .block-img .bg-img-square, .block-intro .block-img .bg-img-almost-square, .block-intro .block-img .bg-img-landscape, .block-intro .block-img .bg-img-landscape-sm, .block-intro .block-img .bg-img-portrait {
          height: 280px; } }
  .block-intro .tag_img {
    content: "";
    width: 504px;
    height: 530px;
    position: absolute;
    bottom: 0;
    right: -150px;
    background-image: url("../img/icon/tag_gb.svg"); }
  .block-intro .logo-center {
    margin: auto;
    max-height: 130px;
    max-width: 170px;
    display: block; }
  .block-intro .disp-mob img {
    margin: auto;
    display: block;
    width: 100%; }

#block-chiffres {
  position: relative;
  overflow: hidden;
  height: 867px;
  width: 100%;
  background-size: cover;
  background-position: center;
  color: white; }
  @media (max-width: 991.98px) {
    #block-chiffres {
      height: initial; } }
  #block-chiffres:before {
    content: "";
    height: 867px;
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5; }
    @media (max-width: 991.98px) {
      #block-chiffres:before {
        height: initial; } }
  #block-chiffres h2 {
    color: white; }
  #block-chiffres .details {
    height: 867px; }
    @media (max-width: 991.98px) {
      #block-chiffres .details {
        height: initial; } }
    #block-chiffres .details .infos {
      width: 100%;
      margin: auto; }

#block-values {
  position: relative;
  overflow: hidden; }
  #block-values .values .item {
    cursor: pointer;
    margin-bottom: 70px; }
    @media (max-width: 991.98px) {
      #block-values .values .item {
        margin-bottom: 15px; } }
    #block-values .values .item:hover .title, #block-values .values .item.active .title {
      opacity: 1; }
      #block-values .values .item:hover .title:after, #block-values .values .item.active .title:after {
        bottom: 0;
        left: 15px;
        opacity: 0.2; }
    #block-values .values .item:hover .text, #block-values .values .item.active .text {
      opacity: 1;
      height: initial; }
    #block-values .values .item:nth-child(1n) .title {
      color: #4b6e83; }
      #block-values .values .item:nth-child(1n) .title:after {
        content: "";
        background-color: #4b6e83; }
    #block-values .values .item:nth-child(2n) .title {
      color: #579abf; }
      #block-values .values .item:nth-child(2n) .title:after {
        content: "";
        background-color: #579abf; }
    #block-values .values .item:nth-child(3n) .title {
      color: #d77c29; }
      #block-values .values .item:nth-child(3n) .title:after {
        content: "";
        background-color: #d77c29; }
    #block-values .values .item:nth-child(4n) .title {
      color: #b4551f; }
      #block-values .values .item:nth-child(4n) .title:after {
        content: "";
        background-color: #b4551f; }
    #block-values .values .item .title {
      opacity: 0.4;
      transition: all ease 0.3s;
      position: relative;
      width: fit-content;
      font-family: "ITC Avant Garde Gothic Std";
      font-style: normal;
      font-weight: 275;
      font-size: 48px;
      line-height: 58px;
      z-index: 1;
      text-transform: uppercase;
      display: inline-block; }
      @media (max-width: 767.98px) {
        #block-values .values .item .title {
          font-size: 38px;
          line-height: 48px; } }
      @media (max-width: 575.98px) {
        #block-values .values .item .title {
          font-size: 30px;
          line-height: 38px; } }
      #block-values .values .item .title:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 30px;
        bottom: 15px;
        left: 0;
        opacity: 0;
        border-radius: 4px;
        transition: all ease 0.3s; }
        @media (max-width: 991.98px) {
          #block-values .values .item .title:after {
            height: 20px;
            left: 8px; } }
    #block-values .values .item .text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-top: 15px;
      margin-bottom: 15px;
      opacity: 0;
      height: 0; }
  #block-values .bg .bg-img, #block-values .bg .bg-img-square, #block-values .bg .bg-img-almost-square, #block-values .bg .bg-img-landscape, #block-values .bg .bg-img-landscape-sm, #block-values .bg .bg-img-portrait {
    height: 950px; }
    @media (max-width: 1199.98px) {
      #block-values .bg .bg-img, #block-values .bg .bg-img-square, #block-values .bg .bg-img-almost-square, #block-values .bg .bg-img-landscape, #block-values .bg .bg-img-landscape-sm, #block-values .bg .bg-img-portrait {
        height: 700px; } }
    @media (max-width: 991.98px) {
      #block-values .bg .bg-img, #block-values .bg .bg-img-square, #block-values .bg .bg-img-almost-square, #block-values .bg .bg-img-landscape, #block-values .bg .bg-img-landscape-sm, #block-values .bg .bg-img-portrait {
        height: 360px; } }
  #block-values .tag_img_blue {
    content: "";
    width: 602px;
    height: 628px;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-size: cover;
    background-image: url("../img/icon/tag_gb_blue.svg"); }
    @media (max-width: 991.98px) {
      #block-values .tag_img_blue {
        width: 408px;
        height: 408px;
        opacity: 0.4; } }

#block-activities {
  position: relative;
  overflow: hidden; }
  #block-activities .block-item {
    width: 100%;
    cursor: pointer;
    text-decoration: none; }
    #block-activities .block-item .block-img {
      display: block;
      overflow: hidden;
      height: 340px;
      width: 100%; }
      #block-activities .block-item .block-img .bg-img, #block-activities .block-item .block-img .bg-img-square, #block-activities .block-item .block-img .bg-img-almost-square, #block-activities .block-item .block-img .bg-img-landscape, #block-activities .block-item .block-img .bg-img-landscape-sm, #block-activities .block-item .block-img .bg-img-portrait {
        display: block;
        background-size: cover;
        transform: scale(1);
        transition: all ease 0.8s;
        height: 340px;
        background-position: center; }
  #block-activities .details {
    display: block;
    text-align: center; }
    #block-activities .details .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: block;
      color: #28485a; }
    #block-activities .details .mea {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: block;
      opacity: 0.7;
      color: #28485a; }

#block-gouvernance {
  position: relative;
  overflow: hidden; }
  #block-gouvernance .block-item {
    width: 100%;
    cursor: pointer;
    text-decoration: none; }
    #block-gouvernance .block-item .block-img {
      display: block;
      overflow: hidden;
      height: 290px;
      width: 100%; }
      #block-gouvernance .block-item .block-img .bg-img, #block-gouvernance .block-item .block-img .bg-img-square, #block-gouvernance .block-item .block-img .bg-img-almost-square, #block-gouvernance .block-item .block-img .bg-img-landscape, #block-gouvernance .block-item .block-img .bg-img-landscape-sm, #block-gouvernance .block-item .block-img .bg-img-portrait {
        display: block;
        background-size: cover;
        transform: scale(1);
        transition: all ease 0.4s;
        height: 290px; }
        @media (max-width: 1199.98px) {
          #block-gouvernance .block-item .block-img .bg-img, #block-gouvernance .block-item .block-img .bg-img-square, #block-gouvernance .block-item .block-img .bg-img-almost-square, #block-gouvernance .block-item .block-img .bg-img-landscape, #block-gouvernance .block-item .block-img .bg-img-landscape-sm, #block-gouvernance .block-item .block-img .bg-img-portrait {
            max-width: 220px; } }
        @media (max-width: 767.98px) {
          #block-gouvernance .block-item .block-img .bg-img, #block-gouvernance .block-item .block-img .bg-img-square, #block-gouvernance .block-item .block-img .bg-img-almost-square, #block-gouvernance .block-item .block-img .bg-img-landscape, #block-gouvernance .block-item .block-img .bg-img-landscape-sm, #block-gouvernance .block-item .block-img .bg-img-portrait {
            max-width: 200px;
            margin: auto; } }
  #block-gouvernance .details {
    display: block; }
    @media (max-width: 767.98px) {
      #block-gouvernance .details {
        text-align: center; } }
    #block-gouvernance .details .infos {
      min-height: 140px; }
      @media (max-width: 1199.98px) {
        #block-gouvernance .details .infos {
          min-height: 105px; } }
      @media (max-width: 767.98px) {
        #block-gouvernance .details .infos {
          min-height: initial; } }
      #block-gouvernance .details .infos .name {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.7; }
      #block-gouvernance .details .infos .role {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.7; }
    #block-gouvernance .details .rs {
      float: left; }
      @media (max-width: 767.98px) {
        #block-gouvernance .details .rs {
          float: initial;
          width: 133px;
          margin: auto; } }
      #block-gouvernance .details .rs a {
        height: 56px;
        width: 56px;
        display: block;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        float: left;
        position: relative;
        transition: all ease 0.3s; }
        #block-gouvernance .details .rs a:not(:last-child) {
          margin-right: 20px; }
        #block-gouvernance .details .rs a.in {
          border: 1px solid #b4551f; }
          #block-gouvernance .details .rs a.in:before {
            content: "";
            height: 16px;
            width: 16px;
            background-size: cover;
            position: absolute;
            left: 19px;
            top: 19px;
            display: block;
            background-image: url("../img/icon/in.svg"); }
        #block-gouvernance .details .rs a.tw {
          border: 1px solid #e09b3d; }
          #block-gouvernance .details .rs a.tw:before {
            content: "";
            height: 16px;
            width: 16px;
            background-size: cover;
            position: absolute;
            left: 19px;
            top: 19px;
            display: block;
            background-image: url("../img/icon/tw.svg"); }
        #block-gouvernance .details .rs a:hover {
          opacity: 0.5;
          transition: all ease 0.3s; }
  #block-gouvernance .tag_img {
    content: "";
    width: 504px;
    height: 530px;
    position: absolute;
    top: 100px;
    left: -116px;
    z-index: -1;
    background-image: url("../img/icon/tag_gb.svg"); }

#block-history {
  position: relative;
  z-index: 0;
  background-color: #ddebf2; }
  @media (max-width: 991.98px) {
    #block-history {
      overflow: hidden; } }
  #block-history .history {
    z-index: 1;
    text-transform: uppercase; }
    @media (max-width: 767.98px) {
      #block-history .history {
        font-size: 38px;
        line-height: 48px; } }
    @media (max-width: 575.98px) {
      #block-history .history {
        font-size: 30px;
        line-height: 38px; } }
    @media (max-width: 991.98px) {
      #block-history .history .block-history {
        text-align: center; } }
    #block-history .history .block-history .item {
      cursor: pointer;
      transition: all ease 0.3s;
      position: relative;
      width: fit-content;
      margin-bottom: 25px; }
      @media (max-width: 991.98px) {
        #block-history .history .block-history .item {
          float: inherit;
          margin: auto;
          display: inline-block;
          padding: 0 9px; } }
      #block-history .history .block-history .item:hover .date, #block-history .history .block-history .item.active .date {
        font-family: "Poppins";
        font-weight: 600;
        opacity: 1; }
        #block-history .history .block-history .item:hover .date:before, #block-history .history .block-history .item.active .date:before {
          content: "";
          background-color: #28485a; }
      #block-history .history .block-history .item .date {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 200;
        font-size: 22px;
        line-height: 28px;
        color: #28485a;
        opacity: 0.5;
        letter-spacing: -1px; }
        #block-history .history .block-history .item .date:before {
          content: "";
          width: 45px;
          height: 1px;
          display: block;
          background-color: transparent;
          float: left;
          margin-top: 13px;
          margin-right: 30px; }
          @media (max-width: 991.98px) {
            #block-history .history .block-history .item .date:before {
              content: none !important; } }
    #block-history .history .block-title {
      font-family: "ITC Avant Garde Gothic Std";
      font-style: normal;
      font-weight: 600;
      font-size: 27px;
      line-height: 36px;
      text-transform: initial;
      color: #28485a; }
    #block-history .history .block-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      text-transform: initial;
      color: #28485a;
      opacity: 0.7; }
  #block-history .tag_img_blue {
    content: "";
    width: 100%;
    height: 628px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: -1;
    background-size: cover;
    background-image: url("../img/icon/tag_gb_blue.svg"); }
    @media (max-width: 991.98px) {
      #block-history .tag_img_blue {
        width: 408px;
        height: 408px;
        opacity: 0.4; } }
  #block-history .bg {
    height: 631px;
    width: 100%;
    position: absolute;
    right: 0;
    z-index: 1; }
    @media (max-width: 991.98px) {
      #block-history .bg {
        margin-top: 20px;
        margin-bottom: 40px;
        position: initial;
        height: initial; } }
    #block-history .bg .bg-history {
      height: 631px; }
      @media (max-width: 1199.98px) {
        #block-history .bg .bg-history {
          height: 620px; } }
      @media (max-width: 991.98px) {
        #block-history .bg .bg-history {
          height: 360px; } }

#block-text_center {
  position: relative;
  min-height: 530px; }
  @media (max-width: 767.98px) {
    #block-text_center {
      min-height: 410px; } }
  @media (max-width: 575.98px) {
    #block-text_center {
      min-height: initial; } }
  #block-text_center .tag_img {
    content: "";
    width: 504px;
    height: 530px;
    position: absolute;
    top: 0;
    bottom: inherit;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
    z-index: -1;
    background-size: cover;
    background-image: url("../img/icon/tag_gb.svg"); }
    @media (max-width: 767.98px) {
      #block-text_center .tag_img {
        width: 300px;
        height: 317px; } }

#block-eta #swiper-slideseta .item {
  width: 100%;
  text-decoration: none; }
  #block-eta #swiper-slideseta .item .img {
    width: 100%;
    height: 685px;
    display: block;
    background-position: center;
    background-size: cover; }
    @media (max-width: 991.98px) {
      #block-eta #swiper-slideseta .item .img {
        height: 605px; } }
    @media (max-width: 767.98px) {
      #block-eta #swiper-slideseta .item .img {
        height: 445px; } }
    @media (max-width: 575.98px) {
      #block-eta #swiper-slideseta .item .img {
        height: 375px; } }
  #block-eta #swiper-slideseta .item .name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 100;
    font-size: 30px;
    line-height: 36px;
    margin: 40px 30px;
    display: block;
    color: #4b6e83; }

#block-txt-color-img {
  position: relative;
  height: 720px; }
  @media (max-width: 991.98px) {
    #block-txt-color-img {
      margin-top: 0;
      height: inherit; } }
  #block-txt-color-img .block-content {
    overflow: hidden;
    z-index: 1;
    position: relative; }
    @media (max-width: 991.98px) {
      #block-txt-color-img .block-content {
        padding: 0; } }
    #block-txt-color-img .block-content h2 {
      font-family: "ITC Avant Garde Gothic Std";
      font-style: normal;
      font-weight: 300;
      font-size: 48px;
      line-height: 58px;
      color: white;
      text-align: left; }
      @media (max-width: 991.98px) {
        #block-txt-color-img .block-content h2 {
          font-size: 30px;
          line-height: 34px; } }
      @media (max-width: 767.98px) {
        #block-txt-color-img .block-content h2 {
          font-size: 24px;
          line-height: 28px; } }
      #block-txt-color-img .block-content h2 b,
      #block-txt-color-img .block-content h2 strong {
        font-family: "ITC Avant Garde Gothic Std";
        font-weight: 400; }
    #block-txt-color-img .block-content .cms {
      color: #ffffff; }
  #block-txt-color-img .block-bg {
    height: 720px;
    width: 60%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; }
    @media (max-width: 991.98px) {
      #block-txt-color-img .block-bg {
        width: 100%;
        height: 340px;
        position: relative; } }
  #block-txt-color-img:after {
    content: "";
    width: 413px;
    height: 531px;
    position: absolute;
    bottom: -130px;
    right: 0;
    z-index: -1;
    background-size: cover;
    background-image: url("../img/icon/tag_gb_cut.svg"); }
  #block-txt-color-img.inverse .block-bg {
    right: 0;
    left: initial; }

.block-slider-engag {
  height: 636px; }
  @media (max-width: 991.98px) {
    .block-slider-engag {
      height: initial; } }
  .block-slider-engag .swiper-slider-engag {
    height: 636px; }
    @media (max-width: 991.98px) {
      .block-slider-engag .swiper-slider-engag {
        height: initial; } }
    .block-slider-engag .swiper-slider-engag .swiper-slide .item {
      width: 50%;
      float: left; }
      @media (max-width: 991.98px) {
        .block-slider-engag .swiper-slider-engag .swiper-slide .item {
          width: 100%;
          float: initial; } }
      .block-slider-engag .swiper-slider-engag .swiper-slide .item.img {
        height: 636px;
        display: block;
        background-position: center;
        background-size: cover; }
        @media (max-width: 991.98px) {
          .block-slider-engag .swiper-slider-engag .swiper-slide .item.img {
            height: 300px;
            margin-bottom: 15px; } }
      .block-slider-engag .swiper-slider-engag .swiper-slide .item .nbr {
        font-family: "ITC Avant Garde Gothic Std";
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 74px;
        color: #b4551f; }
      .block-slider-engag .swiper-slider-engag .swiper-slide .item h2 {
        text-align: left;
        font-family: "ITC Avant Garde Gothic Std";
        font-style: normal;
        font-weight: 300;
        font-size: 40px;
        line-height: 48px;
        color: #1a1a1a; }
        @media (max-width: 991.98px) {
          .block-slider-engag .swiper-slider-engag .swiper-slide .item h2 {
            font-size: 30px;
            line-height: 34px; } }
        @media (max-width: 767.98px) {
          .block-slider-engag .swiper-slider-engag .swiper-slide .item h2 {
            font-size: 24px;
            line-height: 28px; } }
      .block-slider-engag .swiper-slider-engag .swiper-slide .item .text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #3c4135;
        opacity: 0.7; }

#block-chiffres-mot {
  position: relative;
  overflow: hidden;
  height: 867px;
  width: 100%;
  background-size: cover;
  background-position: center;
  color: white; }
  @media (max-width: 991.98px) {
    #block-chiffres-mot {
      height: 960px; } }
  #block-chiffres-mot:before {
    content: "";
    height: 867px;
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5; }
  #block-chiffres-mot h2 {
    color: white; }
  #block-chiffres-mot .details {
    height: 867px; }
    @media (max-width: 991.98px) {
      #block-chiffres-mot .details {
        height: 960px; } }
    #block-chiffres-mot .details .infos {
      width: 100%;
      margin: auto; }
      #block-chiffres-mot .details .infos .numbers {
        border-top: 1px solid white;
        border-bottom: 1px solid white; }
        #block-chiffres-mot .details .infos .numbers .number {
          font-family: "Avenir Next";
          font-style: normal;
          font-weight: 300;
          font-size: 80px;
          line-height: 90px; }
          @media (max-width: 1199.98px) {
            #block-chiffres-mot .details .infos .numbers .number {
              font-size: 70px;
              line-height: 80px; } }
          @media (max-width: 991.98px) {
            #block-chiffres-mot .details .infos .numbers .number {
              font-size: 60px;
              line-height: 85px; } }
        #block-chiffres-mot .details .infos .numbers .unity {
          font-family: "Avenir Next";
          font-style: normal;
          font-weight: 300;
          font-size: 64px;
          line-height: 70px; }
          @media (max-width: 1199.98px) {
            #block-chiffres-mot .details .infos .numbers .unity {
              font-size: 44px;
              line-height: 48px; } }

#block-files {
  position: relative; }
  #block-files .files {
    text-align: center; }
    #block-files .files .file {
      width: 100%;
      padding: 20px;
      text-decoration: none;
      position: relative; }
      #block-files .files .file span {
        padding: 8px 0px;
        display: inline-block;
        border-bottom: 1px solid black; }
        #block-files .files .file span .ddl {
          padding-left: 35px; }
  #block-files .tag_img {
    content: "";
    width: 504px;
    height: 530px;
    position: absolute;
    top: inherit;
    bottom: -140px;
    left: 0;
    right: inherit;
    margin: auto;
    display: block;
    z-index: -1;
    background-size: cover;
    background-image: url("../img/icon/tag_gb.svg"); }
    @media (max-width: 767.98px) {
      #block-files .tag_img {
        width: 300px;
        height: 317px; } }

#block-2-col {
  z-index: 0;
  position: relative;
  background: rgba(87, 154, 191, 0.2); }
  #block-2-col .title-cta {
    color: var(--blue, #28485a);
    font-size: 48px;
    line-height: initial;
    font-family: ITC "Avant Garde Gothic Std";
    font-weight: 400;
    text-align: left; }
  #block-2-col .files {
    text-align: center; }
    #block-2-col .files .file {
      width: 100%;
      padding: 20px;
      text-decoration: none;
      position: relative; }
      #block-2-col .files .file span {
        padding: 8px 0px;
        display: inline-block;
        border-bottom: 1px solid black; }
        #block-2-col .files .file span .ddl {
          padding-left: 35px; }
  #block-2-col .tag_img {
    content: "";
    width: 654px;
    height: 490px;
    position: absolute;
    top: inherit;
    bottom: -50px;
    left: -80px;
    right: inherit;
    margin: auto;
    display: block;
    z-index: -1;
    background-size: cover;
    background-image: url("../img/icon/tag_gb_w.svg"); }
    @media (max-width: 767.98px) {
      #block-2-col .tag_img {
        width: 300px;
        height: 317px; } }

#ot-sdk-cookie-policy {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
  color: #3c4135; }
  #ot-sdk-cookie-policy #cookie-policy-title {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 44px;
    text-align: initial;
    color: #28485a;
    margin-top: 30px;
    margin-bottom: 10px; }
  #ot-sdk-cookie-policy #cookie-policy-description {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #3c4135; }

.title-cta {
  font-family: ITC "Avant Garde Gothic Std";
  font-weight: 400; }

/*
|--------------------
|     PAGE HOME
|--------------------
*/
.home .banner-home {
  color: white;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-color: #28485a;
  height: 770px;
  min-height: 720px; }
  .home .banner-home.filter:before {
    content: "";
    opacity: 0.3;
    background-color: black;
    position: absolute;
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .home .banner-home.filter:before {
    content: "";
    display: block; }
  .home .banner-home .item-content {
    padding-bottom: 50px; }
    .home .banner-home .item-content hr {
      width: 215px;
      height: 8px;
      margin: auto;
      border: none;
      background: white; }
    .home .banner-home .item-content .suptitle {
      font-family: "Avenir Next";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      text-transform: uppercase;
      display: block; }
      @media (max-width: 991.98px) {
        .home .banner-home .item-content .suptitle {
          font-size: 24px; } }
      @media (max-width: 767.98px) {
        .home .banner-home .item-content .suptitle {
          font-size: 20px;
          line-height: 24px; } }
    .home .banner-home .item-content h1 {
      font-weight: 300;
      font-size: 64px;
      line-height: 66px; }
      @media (max-width: 991.98px) {
        .home .banner-home .item-content h1 {
          font-size: 42px;
          line-height: 44px; } }
    .home .banner-home .item-content .item-text {
      font-size: 16px;
      line-height: 24px;
      text-align: center; }

.home .section-activities {
  margin-top: -210px; }
  @media (max-width: 1199.98px) {
    .home .section-activities {
      margin-top: -140; } }
  @media (max-width: 991.98px) {
    .home .section-activities {
      margin-top: -210px; } }
  .home .section-activities h2 {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 225;
    text-align: center;
    padding-top: 34px !important;
    color: #28485a;
    text-align: center;
    font-size: 40px;
    font-weight: 275; }
  .home .section-activities .items-activity .item-activity-txt {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #28485a; }
    @media (max-width: 1399.98px) {
      .home .section-activities .items-activity .item-activity-txt {
        font-size: 17px; } }
    .home .section-activities .items-activity .item-activity-txt .cms {
      padding-left: 30px;
      border-left: 2px solid #28485a; }
  .home .section-activities .items-activity .item-activity .block-item {
    width: 100%;
    background-color: white;
    cursor: pointer;
    text-decoration: none; }
    .home .section-activities .items-activity .item-activity .block-item .block-img {
      height: 403px; }
      @media (max-width: 575.98px) {
        .home .section-activities .items-activity .item-activity .block-item .block-img {
          height: 280px; } }
      .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img, .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img-square, .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img-almost-square, .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img-landscape, .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img-landscape-sm, .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img-portrait {
        height: 403px; }
        @media (max-width: 575.98px) {
          .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img, .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img-square, .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img-almost-square, .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img-landscape, .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img-landscape-sm, .home .section-activities .items-activity .item-activity .block-item .block-img .bg-img-portrait {
            height: 280px; } }
      .home .section-activities .items-activity .item-activity .block-item .block-img h2 {
        text-align: left;
        padding-top: 38px !important;
        transition: all ease 0.4s; }
    .home .section-activities .items-activity .item-activity .block-item:hover .bg-img, .home .section-activities .items-activity .item-activity .block-item:hover .bg-img-square, .home .section-activities .items-activity .item-activity .block-item:hover .bg-img-almost-square, .home .section-activities .items-activity .item-activity .block-item:hover .bg-img-landscape, .home .section-activities .items-activity .item-activity .block-item:hover .bg-img-landscape-sm, .home .section-activities .items-activity .item-activity .block-item:hover .bg-img-portrait {
      transform: scale(1.04);
      transition: all ease 0.3s; }
    .home .section-activities .items-activity .item-activity .block-item:hover h2 {
      transition: all ease 0.4s;
      background-color: #3f6376;
      color: white; }
  .home .section-activities .items-activity .item-cta .title-cta {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 41px;
    letter-spacing: 0.08em;
    text-align: center;
    color: #4b6e83; }
  .home .section-activities .items-activity .item-cta a {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    border: 1px solid rgba(40, 72, 90, 0.5);
    padding: 22px 100px;
    text-decoration: inherit;
    margin: auto;
    display: table;
    text-align: center;
    min-width: 280px;
    transition: all ease 0.3s; }
    .home .section-activities .items-activity .item-cta a:hover {
      color: white;
      background-color: #28485a;
      transition: all ease 0.3s; }
    @media (max-width: 767.98px) {
      .home .section-activities .items-activity .item-cta a {
        padding: 17px 40px;
        min-width: 230px; } }

.home .section-mot-du-fondateur {
  background-position: center;
  position: relative; }
  .home .section-mot-du-fondateur:before {
    content: "";
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    z-index: 1; }
  .home .section-mot-du-fondateur .filigrane-b {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0; }
  .home .section-mot-du-fondateur .le-mot-du-fondateur .cadre {
    border: 1px solid white;
    padding: 70px 30px 100px 30px; }
  .home .section-mot-du-fondateur .le-mot-du-fondateur .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.56; }
  .home .section-mot-du-fondateur .le-mot-du-fondateur .text {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 300;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #ffffff; }
    @media (max-width: 991.98px) {
      .home .section-mot-du-fondateur .le-mot-du-fondateur .text {
        font-size: 24px;
        line-height: 30px; } }
    @media (max-width: 991.98px) {
      .home .section-mot-du-fondateur .le-mot-du-fondateur .text {
        font-size: 20px;
        line-height: 26px; } }
  .home .section-mot-du-fondateur .le-mot-du-fondateur .signature {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #ffffff; }

.home .section-a-propos {
  margin-top: -150px;
  z-index: 2;
  position: relative; }
  @media (max-width: 991.98px) {
    .home .section-a-propos {
      margin-top: 60px;
      text-align: center; } }
  .home .section-a-propos .block-img-a-propos {
    height: 950px;
    width: 100%;
    overflow: hidden; }
    @media (max-width: 991.98px) {
      .home .section-a-propos .block-img-a-propos {
        height: 500px; } }
    .home .section-a-propos .block-img-a-propos .img-a-propos {
      height: 950px;
      width: 100%;
      background-size: cover;
      background-position: center;
      transform: scale(1);
      transition: all ease 0.8s; }
      @media (max-width: 991.98px) {
        .home .section-a-propos .block-img-a-propos .img-a-propos {
          height: 500px; } }
    .home .section-a-propos .block-img-a-propos:hover .img-a-propos {
      transform: scale(1.04);
      transition: all ease 0.8s; }
  .home .section-a-propos h2 {
    font-weight: 400;
    text-align: left; }
    @media (max-width: 991.98px) {
      .home .section-a-propos h2 {
        text-align: center; } }
  .home .section-a-propos .text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #3c4135; }

.home .section-actu h2 {
  font-family: "ITC Avant Garde Gothic Std";
  font-style: normal;
  font-weight: 400; }

.home .section-actu .custom-card h2 {
  font-family: "ITC Avant Garde Gothic Std";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-transform: inherit; }

.home .section-actu .custom-card .card-img-container {
  height: 530px; }
  .home .section-actu .custom-card .card-img-container .card-img {
    height: 530px; }

.home .section-actu .custom-card .details {
  padding: 10px 20px; }
  .home .section-actu .custom-card .details h2 {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px; }

/*
|--------------------
|      NEWS
|--------------------
*/
.section-actualite {
  position: relative;
  overflow: hidden;
  margin-top: -90px; }
  .section-actualite .tag_img {
    content: "";
    width: 384px;
    height: 405px;
    position: absolute;
    top: 280px;
    bottom: initial;
    right: -100px;
    background-image: url(../img/icon/tag_gb.svg);
    background-size: cover; }
  .section-actualite .first-custom-card:hover .card-img-container .card-img {
    transform: initial; }
  .section-actualite .first-custom-card h2 {
    font-size: 48px !important;
    line-height: 58px !important; }
  .section-actualite .first-custom-card .swiper-slide {
    height: 660px; }
  .section-actualite .first-custom-card .block-swiper-buttons {
    height: 54px; }
    .section-actualite .first-custom-card .block-swiper-buttons .swiper-buttons {
      top: initial;
      left: 0;
      position: absolute;
      bottom: 0px;
      z-index: 1;
      height: 54px; }
  .section-actualite .swiper-buttons {
    position: initial; }

.load-more-container {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .load-more-container .loader-container {
    display: none; }

/*
|--------------------
|     PAGE CMS
|--------------------
*/
/*
|--------------------
|     PAGE Communiqués
|--------------------
*/
.section-communiques .elem-communique {
  position: relative;
  display: flex; }
  .section-communiques .elem-communique:not(:last-child) {
    border-bottom: 1px solid #767a72; }
  .section-communiques .elem-communique:hover .infos,
  .section-communiques .elem-communique:hover .link {
    transition: all ease 0.2s;
    opacity: 0.6; }
  .section-communiques .elem-communique .infos {
    float: left;
    width: 90%;
    transition: all ease 0.2s;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px; }
    .section-communiques .elem-communique .infos .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px; }
    .section-communiques .elem-communique .infos .date {
      font-size: 18px;
      opacity: 0.5;
      font-style: italic; }
  .section-communiques .elem-communique .link {
    position: absolute;
    right: 0;
    top: calc(50% - 15px); }
    .section-communiques .elem-communique .link:after {
      content: "";
      height: 30px;
      width: 30px;
      vertical-align: middle;
      align-items: center;
      display: flex;
      background-position: center;
      background-image: url("../img/icon/download.svg"); }

/*
|--------------------
|      SINGLE
|--------------------
*/
.post-date {
  font-size: 12px;
  color: rgba(118, 122, 114, 0.6);
  text-transform: uppercase;
  font-weight: 400; }

.post-excerpt {
  font-size: 22px;
  color: rgba(26, 26, 26, 0.6);
  font-weight: 400; }

#block-gallery .infos {
  width: 100%; }
  #block-gallery .infos #slides-gallery-activ {
    width: 100%;
    overflow: hidden; }
    #block-gallery .infos #slides-gallery-activ .swiper-slide .card-img {
      max-height: 140px;
      max-width: 240px; }
      @media (max-width: 767.98px) {
        #block-gallery .infos #slides-gallery-activ .swiper-slide .card-img {
          max-height: 80px;
          max-width: 120px; } }

/*
|--------------------
|       Contact
|--------------------
*/
#page-contact h2 {
  font-family: "ITC Avant Garde Gothic Std";
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
  text-align: center; }
  @media (max-width: 1199.98px) {
    #page-contact h2 {
      font-size: 38px;
      line-height: 40px; } }
  @media (max-width: 991.98px) {
    #page-contact h2 {
      font-size: 32px;
      line-height: 34px; } }
  @media (max-width: 767.98px) {
    #page-contact h2 {
      font-size: 24px;
      line-height: 28px; } }
  #page-contact h2.h2-alt {
    font-weight: 400;
    font-size: 128px;
    line-height: 155px;
    text-align: left; }

#page-contact .coordonnees {
  margin-bottom: 200px; }
  @media (max-width: 1199.98px) {
    #page-contact .coordonnees {
      margin-bottom: 0px; } }
  #page-contact .coordonnees .bg {
    background-size: cover; }
    #page-contact .coordonnees .bg .infos-coor {
      height: 653px;
      background-size: cover; }
    #page-contact .coordonnees .bg .btns-coor {
      color: white; }
    @media (max-width: 1199.98px) {
      #page-contact .coordonnees .bg .info {
        text-align: center !important; } }
  #page-contact .coordonnees .infos-map {
    position: absolute;
    width: 100%;
    height: 750px;
    background-size: cover;
    background-position: center; }
    @media (max-width: 1199.98px) {
      #page-contact .coordonnees .infos-map {
        height: 653px;
        position: relative;
        top: 0;
        left: 0;
        right: 0; } }
  #page-contact .coordonnees .info2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px; }
    #page-contact .coordonnees .info2 p {
      margin-bottom: 15px; }

#page-contact #block-form {
  margin-top: -200px; }
  #page-contact #block-form #gform_confirmation_wrapper_1 {
    min-height: 290px;
    color: white;
    text-align: center; }
  #page-contact #block-form h2 {
    color: white; }
  #page-contact #block-form .gfield .gfield_label {
    color: white; }
  #page-contact #block-form .gfield_consent_label {
    color: white; }
    #page-contact #block-form .gfield_consent_label a {
      display: initial; }
  #page-contact #block-form .gfield_description {
    padding-top: 16px;
    padding-bottom: 16px; }
  #page-contact #block-form .gform_wrapper .gform_footer .gform_ajax_spinner {
    top: -18px; }

#page-contact .btn, #page-contact .btn-black, #page-contact .btn-white, #page-contact .btn-xs, #page-contact .btn-sm, #page-contact .btn-md, #page-contact .btn-lg {
  padding: 22px 15px; }

/*
|--------------------
|       404
|--------------------
*/
#page-404 .section-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #1a1a1a;
  color: #ffffff;
  text-align: center;
  text-decoration: none !important; }
  #page-404 .section-content .item-title {
    font-size: 88px;
    font-weight: 300;
    text-decoration: none !important; }
    @media (max-width: 1199.98px) {
      #page-404 .section-content .item-title {
        font-size: 50px; } }
    @media (max-width: 991.98px) {
      #page-404 .section-content .item-title {
        font-size: 34px; } }
  #page-404 .section-content .link-white {
    color: white;
    text-decoration: none !important; }
    #page-404 .section-content .link-white a {
      color: white;
      text-decoration: none !important; }

/*
|--------------------
|      BUTTONS
|--------------------
*/
/*
|--------------------
|  Smooth Scrollbar
|--------------------
*/
/*
|
| Scroll area
|--------------
*/
.scroll-area .scrollbar-track.scrollbar-track-y {
  width: 4px !important; }
  .scroll-area .scrollbar-track.scrollbar-track-y .scrollbar-thumb {
    width: 100% !important;
    background: #000000 !important; }

/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/
/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select {
  width: 100%;
  -webkit-appearance: none;
  border: none;
  border-bottom: 1px solid #000000;
  border-radius: 0; }
  input:focus, textarea:focus, select:focus {
    outline: none; }

select {
  background-image: url("../img/icon/bottom.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: calc(100% - 10px) center; }

.validation_error {
  margin-bottom: 30px;
  color: #B00 !important; }

.validation_message {
  margin-top: 5px;
  color: #B00; }

#gform_ajax_spinner {
  margin-left: 10px;
  display: inline-block;
  width: 50px;
  height: 50px; }

/*
|
| Gravity form bootstrap
|-------------------------
*/
/*
|
| Materialize form
|-------------------
*/
.materialize-form .materialize-form-group {
  position: relative;
  margin-bottom: 25px; }
  .materialize-form .materialize-form-group label {
    position: absolute;
    top: 0;
    left: 15px; }
  .materialize-form .materialize-form-group input {
    margin-bottom: 35px;
    padding: 7px 0; }
  .materialize-form .materialize-form-group.gfield_error label {
    top: -20px; }

/*
|
| Page Loader
|--------------
*/
.page-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #28485a;
  display: none; }
  .page-loader.active {
    display: flex; }
  .page-loader .item-content {
    width: 100%;
    color: #1a1a1a;
    text-align: center; }
    .page-loader .item-content .img-logo {
      height: 200px; }
  .page-loader .item-loadbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    margin: auto; }
    .page-loader .item-loadbar .item-loadbar-inner {
      width: 100%;
      height: 100%;
      border-radius: 1px;
      background-color: #1a1a1a;
      animation: loadbar 1.2s cubic-bezier(0.92, 0, 0.09, 1);
      transform-origin: left top; }

@keyframes loadbar {
  from {
    transform: scaleX(0); }
  to {
    transform: scaleX(0.7); } }

[data-splittext] {
  opacity: 0; }
  [data-splittext].split-ready {
    opacity: 1; }

[data-kira-item="splitline"] .item-line {
  overflow: hidden; }

[data-kira-item^="fadeIn"] {
  opacity: 0; }

[data-kira-item^="fadeInLeft.stagger"],
[data-kira-item^="fadeInUp.stagger"] {
  opacity: 1; }
  [data-kira-item^="fadeInLeft.stagger"] [data-stagger-item],
  [data-kira-item^="fadeInUp.stagger"] [data-stagger-item] {
    opacity: 0; }
