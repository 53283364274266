/*
|--------------------
|      NEWS
|--------------------
*/
.section-actualite {
    position: relative;
    overflow: hidden;
    margin-top: -90px;
    .tag_img {
        content: "";
        width: 384px;
        height: 405px;
        position: absolute;
        top: 280px;
        bottom: initial;
        right: -100px;
        background-image: url(../img/icon/tag_gb.svg);
        background-size: cover;
    }
    .first-custom-card {
        &:hover {
            .card-img-container {
                .card-img {
                    transform: initial;
                }
            }
        }
        h2 {
            font-size: 48px !important;
            line-height: 58px !important;
        }
        .swiper-slide {
            height: 660px;
        }
        .block-swiper-buttons {
            height: 54px;
            .swiper-buttons {
                top: initial;
                left: 0;
                position: absolute;
                bottom: 0px;
                z-index: 1;
                height: 54px;
            }
        }
    }
    .swiper-buttons {
        position: initial;
    }
}
.load-more-container {
    min-height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    .loader-container {
        display: none;
    }
}
