/*
|--------------------
|       Contact
|--------------------
*/

#page-contact {
  h2 {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 58px;
    color: #ffffff;
    text-align: center;
    @include media-breakpoint-down(lg) {
      font-size: 38px;
      line-height: 40px;
    }
    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: 34px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 24px;
      line-height: 28px;
    }
    &.h2-alt {
      font-weight: 400;
      font-size: 128px;
      line-height: 155px;
      text-align: left;
    }
  }
  .coordonnees {
    margin-bottom: 200px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 0px;
    }
    .bg {
      background-size: cover;
      .infos-coor {
        height: 653px;
        background-size: cover;
      }
      .btns-coor {
        color: white;
      }
      @include media-breakpoint-down(lg) {
        .info {
          text-align: center !important;
        }
      }
    }
    .infos-map {
      position: absolute;
      width: 100%;
      height: 750px;
      background-size: cover;
      background-position: center;
      @include media-breakpoint-down(lg) {
        height: 653px;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
      }
    }
    .info2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      p {
        margin-bottom: 15px;
      }
    }
  }
  #block-form {
    margin-top: -200px;
    #gform_confirmation_wrapper_1 {
      min-height: 290px;
      color: white;
      text-align: center;
    }
    h2 {
      color: white;
    }
    .gfield {
      .gfield_label {
        color: white;
      }
    }
    .gfield_consent_label {
      color: white;
      a {
        display: initial;
      }
    }
    .gfield_description {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .gform_wrapper .gform_footer .gform_ajax_spinner {
      top: -18px;
    }
  }
  .btn {
    padding: 22px 15px;
  }
}
