/*
|--------------------
|      BLOCKS
|--------------------
*/

.block-intro {
  margin-top: -150px;
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    margin-top: 15px;
  }

  .block-img {
    height: 1020px;
    @include media-breakpoint-down(md) {
      height: 280px;
    }
    .bg-img {
      height: 1020px;
      @include media-breakpoint-down(md) {
        height: 280px;
      }
    }
  }
  .tag_img {
    content: "";
    width: 504px;
    height: 530px;
    position: absolute;
    bottom: 0;
    right: -150px;
    background-image: url("../img/icon/tag_gb.svg");
  }
  .logo-center {
    margin: auto;
    max-height: 130px;
    max-width: 170px;
    display: block;
  }
  .disp-mob {
    img {
      margin: auto;
      display: block;
      width: 100%;
    }
  }
}

#block-chiffres {
  position: relative;
  overflow: hidden;
  height: 867px;
  width: 100%;
  background-size: cover;
  background-position: center;
  color: white;
  @include media-breakpoint-down(md) {
    height: initial;
  }
  &:before {
    content: "";
    height: 867px;
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    @include media-breakpoint-down(md) {
      height: initial;
    }
  }
  h2 {
    color: white;
  }
  .details {
    height: 867px;
    @include media-breakpoint-down(md) {
      height: initial;
    }
    .infos {
      width: 100%;
      margin: auto;
    }
  }
}

#block-values {
  position: relative;
  overflow: hidden;
  .values {
    .item {
      cursor: pointer;
      margin-bottom: 70px;
      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }
      &:hover,
      &.active {
        .title {
          opacity: 1;
          &:after {
            bottom: 0;
            left: 15px;
            opacity: 0.2;
          }
        }
        .text {
          opacity: 1;
          height: initial;
        }
      }
      &:nth-child(1n) {
        .title {
          color: #4b6e83;
          &:after {
            content: "";
            background-color: #4b6e83;
          }
        }
      }
      &:nth-child(2n) {
        .title {
          color: #579abf;
          &:after {
            content: "";
            background-color: #579abf;
          }
        }
      }
      &:nth-child(3n) {
        .title {
          color: #d77c29;
          &:after {
            content: "";
            background-color: #d77c29;
          }
        }
      }
      &:nth-child(4n) {
        .title {
          color: #b4551f;
          &:after {
            content: "";
            background-color: #b4551f;
          }
        }
      }
      .title {
        opacity: 0.4;
        transition: all ease 0.3s;
        position: relative;
        width: fit-content;
        font-family: "ITC Avant Garde Gothic Std";
        font-style: normal;
        font-weight: 275;
        font-size: 48px;
        line-height: 58px;
        z-index: 1;
        text-transform: uppercase;
        display: inline-block;
        @include media-breakpoint-down(sm) {
          font-size: 38px;
          line-height: 48px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 30px;
          line-height: 38px;
        }
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 30px;
          bottom: 15px;
          left: 0;
          opacity: 0;
          border-radius: 4px;
          transition: all ease 0.3s;
          @include media-breakpoint-down(md) {
            height: 20px;
            left: 8px;
          }
        }
      }
      .text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 15px;
        margin-bottom: 15px;
        opacity: 0;
        height: 0;
      }
    }
  }
  .bg {
    .bg-img {
      height: 950px;
      @include media-breakpoint-down(lg) {
        height: 700px;
      }
      @include media-breakpoint-down(md) {
        height: 360px;
      }
    }
  }
  .tag_img_blue {
    content: "";
    width: 602px;
    height: 628px;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-size: cover;
    background-image: url("../img/icon/tag_gb_blue.svg");
    @include media-breakpoint-down(md) {
      width: 408px;
      height: 408px;
      opacity: 0.4;
    }
  }
}

#block-activities {
  position: relative;
  overflow: hidden;

  .block-item {
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    .block-img {
      display: block;
      overflow: hidden;
      height: 340px;
      width: 100%;
      .bg-img {
        display: block;
        background-size: cover;
        transform: scale(1);
        transition: all ease 0.8s;
        height: 340px;
        background-position: center;
      }
    }
  }
  .details {
    display: block;
    text-align: center;
    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: block;
      color: $blue2;
    }
    .mea {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: block;
      opacity: 0.7;
      color: $blue2;
    }
  }
}

#block-gouvernance {
  position: relative;
  overflow: hidden;

  .block-item {
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    .block-img {
      display: block;
      overflow: hidden;
      height: 290px;
      width: 100%;
      .bg-img {
        display: block;
        background-size: cover;
        transform: scale(1);
        transition: all ease 0.4s;
        height: 290px;
        @include media-breakpoint-down(lg) {
          max-width: 220px;
        }
        @include media-breakpoint-down(sm) {
          max-width: 200px;
          margin: auto;
        }
      }
    }
  }
  .details {
    display: block;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    .infos {
      min-height: 140px;
      @include media-breakpoint-down(lg) {
        min-height: 105px;
      }
      @include media-breakpoint-down(sm) {
        min-height: initial;
      }
      .name {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.7;
      }
      .role {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.7;
      }
    }
    .rs {
      float: left;
      @include media-breakpoint-down(sm) {
        float: initial;
        width: 133px;
        margin: auto;
      }
      a {
        height: 56px;
        width: 56px;
        display: block;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        float: left;
        position: relative;
        transition: all ease 0.3s;
        &:not(:last-child) {
          margin-right: 20px;
        }
        &.in {
          border: 1px solid #b4551f;
          &:before {
            content: "";
            height: 16px;
            width: 16px;
            background-size: cover;
            position: absolute;
            left: 19px;
            top: 19px;
            display: block;
            background-image: url("../img/icon/in.svg");
          }
        }
        &.tw {
          border: 1px solid #e09b3d;
          &:before {
            content: "";
            height: 16px;
            width: 16px;
            background-size: cover;
            position: absolute;
            left: 19px;
            top: 19px;
            display: block;
            background-image: url("../img/icon/tw.svg");
          }
        }
        &:hover {
          opacity: 0.5;
          transition: all ease 0.3s;
        }
      }
    }
  }
  .tag_img {
    content: "";
    width: 504px;
    height: 530px;
    position: absolute;
    top: 100px;
    left: -116px;
    z-index: -1;
    background-image: url("../img/icon/tag_gb.svg");
  }
}

#block-history {
  position: relative;
  z-index: 0;
  background-color: #ddebf2;
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }

  .history {
    z-index: 1;
    text-transform: uppercase;
    @include media-breakpoint-down(sm) {
      font-size: 38px;
      line-height: 48px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 30px;
      line-height: 38px;
    }
    .block-history {
      @include media-breakpoint-down(md) {
        text-align: center;
      }
      .item {
        cursor: pointer;
        transition: all ease 0.3s;
        position: relative;
        width: fit-content;
        margin-bottom: 25px;
        @include media-breakpoint-down(md) {
          float: inherit;
          margin: auto;
          display: inline-block;
          padding: 0 9px;
        }
        &:hover,
        &.active {
          .date {
            font-family: "Poppins";
            font-weight: 600;
            opacity: 1;
            &:before {
              content: "";
              background-color: $blue2;
            }
          }
        }
        .date {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 200;
          font-size: 22px;
          line-height: 28px;
          color: $blue2;
          opacity: 0.5;
          letter-spacing: -1px;
          &:before {
            content: "";
            width: 45px;
            height: 1px;
            display: block;
            background-color: transparent;
            float: left;
            margin-top: 13px;
            margin-right: 30px;
            @include media-breakpoint-down(md) {
              content: none !important;
            }
          }
        }
      }
    }
    .block-title {
      font-family: "ITC Avant Garde Gothic Std";
      font-style: normal;
      font-weight: 600;
      font-size: 27px;
      line-height: 36px;
      text-transform: initial;
      color: $blue2;
    }
    .block-text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      text-transform: initial;
      color: $blue2;
      opacity: 0.7;
    }
  }
  .tag_img_blue {
    content: "";
    width: 100%;
    height: 628px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: -1;
    background-size: cover;
    background-image: url("../img/icon/tag_gb_blue.svg");
    @include media-breakpoint-down(md) {
      width: 408px;
      height: 408px;
      opacity: 0.4;
    }
  }

  .bg {
    height: 631px;
    width: 100%;
    position: absolute;
    right: 0;
    z-index: 1;
    @include media-breakpoint-down(md) {
      margin-top: 20px;
      margin-bottom: 40px;
      position: initial;
      height: initial;
    }
    .bg-history {
      height: 631px;
      @include media-breakpoint-down(lg) {
        height: 620px;
      }
      @include media-breakpoint-down(md) {
        height: 360px;
      }
    }
  }
}

#block-text_center {
  position: relative;
  min-height: 530px;
  @include media-breakpoint-down(sm) {
    min-height: 410px;
  }
  @include media-breakpoint-down(xs) {
    min-height: initial;
  }

  .tag_img {
    content: "";
    width: 504px;
    height: 530px;
    position: absolute;
    top: 0;
    bottom: inherit;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
    z-index: -1;
    background-size: cover;
    background-image: url("../img/icon/tag_gb.svg");
    @include media-breakpoint-down(sm) {
      width: 300px;
      height: 317px;
    }
  }
}

#block-eta {
  #swiper-slideseta {
    .item {
      width: 100%;
      text-decoration: none;

      .img {
        width: 100%;
        height: 685px;
        display: block;
        background-position: center;
        background-size: cover;
        @include media-breakpoint-down(md) {
          height: 605px;
        }
        @include media-breakpoint-down(sm) {
          height: 445px;
        }
        @include media-breakpoint-down(xs) {
          height: 375px;
        }
      }
      .name {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 100;
        font-size: 30px;
        line-height: 36px;
        margin: 40px 30px;
        display: block;
        color: $blue4;
      }
    }
  }
}

#block-txt-color-img {
  position: relative;
  height: 720px;
  @include media-breakpoint-down(md) {
    margin-top: 0;
    height: inherit;
  }
  .block-content {
    overflow: hidden;
    z-index: 1;
    position: relative;
    @include media-breakpoint-down(md) {
      padding: 0;
    }
    h2 {
      font-family: "ITC Avant Garde Gothic Std";
      font-style: normal;
      font-weight: 300;
      font-size: 48px;
      line-height: 58px;
      color: white;
      text-align: left;
      @include media-breakpoint-down(md) {
        font-size: 30px;
        line-height: 34px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 28px;
      }
      b,
      strong {
        font-family: "ITC Avant Garde Gothic Std";
        font-weight: 400;
      }
    }
    .cms {
      color: #ffffff;
    }
  }
  .block-bg {
    height: 720px;
    width: 60%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    @include media-breakpoint-down(md) {
      width: 100%;
      height: 340px;
      position: relative;
    }
  }
  &:after {
    content: "";
    width: 413px;
    height: 531px;
    position: absolute;
    bottom: -130px;
    right: 0;
    z-index: -1;
    background-size: cover;
    background-image: url("../img/icon/tag_gb_cut.svg");
  }
  &.inverse {
    .block-bg {
      right: 0;
      left: initial;
    }
  }
}

.block-slider-engag {
  height: 636px;

  @include media-breakpoint-down(md) {
    height: initial;
  }
  .swiper-slider-engag {
    height: 636px;
    @include media-breakpoint-down(md) {
      height: initial;
    }
    .swiper-slide {
      .item {
        width: 50%;
        float: left;
        @include media-breakpoint-down(md) {
          width: 100%;
          float: initial;
        }
        &.img {
          height: 636px;
          display: block;
          background-position: center;
          background-size: cover;
          @include media-breakpoint-down(md) {
            height: 300px;
            margin-bottom: 15px;
          }
        }
        .nbr {
          font-family: "ITC Avant Garde Gothic Std";
          font-style: normal;
          font-weight: 400;
          font-size: 64px;
          line-height: 74px;
          color: $orange;
        }
        h2 {
          text-align: left;
          font-family: "ITC Avant Garde Gothic Std";
          font-style: normal;
          font-weight: 300;
          font-size: 40px;
          line-height: 48px;
          color: $very-dark-grey;
          @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 34px;
          }
          @include media-breakpoint-down(sm) {
            font-size: 24px;
            line-height: 28px;
          }
        }
        .text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $grey-alt;
          opacity: 0.7;
        }
      }
    }
  }
}

#block-chiffres-mot {
  position: relative;
  overflow: hidden;
  height: 867px;
  width: 100%;
  background-size: cover;
  background-position: center;
  color: white;
  @include media-breakpoint-down(md) {
    height: 960px;
  }
  &:before {
    content: "";
    height: 867px;
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
  }
  h2 {
    color: white;
  }
  .details {
    height: 867px;
    @include media-breakpoint-down(md) {
      height: 960px;
    }
    .infos {
      width: 100%;
      margin: auto;
      .numbers {
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        .number {
          font-family: "Avenir Next";
          font-style: normal;
          font-weight: 300;
          font-size: 80px;
          line-height: 90px;
          @include media-breakpoint-down(lg) {
            font-size: 70px;
            line-height: 80px;
          }
          @include media-breakpoint-down(md) {
            font-size: 60px;
            line-height: 85px;
          }
        }
        .unity {
          font-family: "Avenir Next";
          font-style: normal;
          font-weight: 300;
          font-size: 64px;
          line-height: 70px;
          @include media-breakpoint-down(lg) {
            font-size: 44px;
            line-height: 48px;
          }
        }
      }
    }
  }
}

#block-files {
  position: relative;
  .files {
    text-align: center;
    .file {
      width: 100%;
      padding: 20px;
      text-decoration: none;
      position: relative;
      span {
        padding: 8px 0px;
        display: inline-block;
        border-bottom: 1px solid black;
        .ddl {
          padding-left: 35px;
        }
      }
    }
  }

  .tag_img {
    content: "";
    width: 504px;
    height: 530px;
    position: absolute;
    top: inherit;
    bottom: -140px;
    left: 0;
    right: inherit;
    margin: auto;
    display: block;
    z-index: -1;
    background-size: cover;
    background-image: url("../img/icon/tag_gb.svg");
    @include media-breakpoint-down(sm) {
      width: 300px;
      height: 317px;
    }
  }
}

#block-2-col {
  z-index: 0;
  position: relative;
  background: rgba(87, 154, 191, 0.2);
  .title-cta {
    color: var(--blue, #28485a);
    font-size: 48px;
    line-height: initial;
    font-family: ITC "Avant Garde Gothic Std";
    font-weight: 400;
    text-align: left;
  }
  .files {
    text-align: center;
    .file {
      width: 100%;
      padding: 20px;
      text-decoration: none;
      position: relative;
      span {
        padding: 8px 0px;
        display: inline-block;
        border-bottom: 1px solid black;
        .ddl {
          padding-left: 35px;
        }
      }
    }
  }

  .tag_img {
    content: "";
    width: 654px;
    height: 490px;
    position: absolute;
    top: inherit;
    bottom: -50px;
    left: -80px;
    right: inherit;
    margin: auto;
    display: block;
    z-index: -1;
    background-size: cover;
    background-image: url("../img/icon/tag_gb_w.svg");
    @include media-breakpoint-down(sm) {
      width: 300px;
      height: 317px;
    }
  }
}

#ot-sdk-cookie-policy {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
  color: #3c4135;
  #cookie-policy-title {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 44px;
    text-align: initial;
    color: #28485a;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  #cookie-policy-description {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #3c4135;
  }
}

.title-cta {
  font-family: ITC "Avant Garde Gothic Std";
  font-weight: 400;
}
