/*
|--------------------
|     PAGE HOME
|--------------------
*/

.home {
  .banner-home {
    color: white;
    text-align: center;
    background-position: center;
    background-size: cover;
    background-color: $blue2;
    height: 770px;
    min-height: 720px;
    &.filter {
      &:before {
        content: "";
        opacity: 0.3;
        background-color: black;
        position: absolute;
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    &.filter {
      &:before {
        content: "";
        display: block;
      }
    }

    .item-content {
      padding-bottom: 50px;

      hr {
        width: 215px;
        height: 8px;
        margin: auto;
        border: none;
        background: white;
      }

      .suptitle {
        font-family: "Avenir Next";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        text-transform: uppercase;
        display: block;
        @include media-breakpoint-down(md) {
          font-size: 24px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      h1 {
        font-weight: 300;
        font-size: 64px;
        line-height: 66px;
        @include media-breakpoint-down(md) {
          font-size: 42px;
          line-height: 44px;
        }
      }

      .item-text {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  .section-activities {
    margin-top: -210px;
    @include media-breakpoint-down(lg) {
      margin-top: -140;
    }
    @include media-breakpoint-down(md) {
      margin-top: -210px;
    }

    h2 {
      font-family: "ITC Avant Garde Gothic Std";
      font-style: normal;
      font-weight: 225;
      text-align: center;
      padding-top: 34px !important;

      color: #28485a;
      text-align: center;
      font-size: 40px;
      font-weight: 275;
    }

    .items-activity {
      .item-activity-txt {
        font-family: "Avenir Next";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: $blue2;

        @include media-breakpoint-down(xl) {
          font-size: 17px;
        }
        .cms {
          padding-left: 30px;
          border-left: 2px solid $blue2;
        }
      }

      .item-activity {
        .block-item {
          width: 100%;
          background-color: white;
          cursor: pointer;
          text-decoration: none;
          .block-img {
            height: 403px;
            @include media-breakpoint-down(xs) {
              height: 280px;
            }
            .bg-img {
              height: 403px;
              @include media-breakpoint-down(xs) {
                height: 280px;
              }
            }
            h2 {
              text-align: left;
              padding-top: 38px !important;
              transition: all ease 0.4s;
            }
          }
          &:hover {
            .bg-img {
              transform: scale(1.04);
              transition: all ease 0.3s;
            }
            h2 {
              transition: all ease 0.4s;
              background-color: #3f6376;
              color: white;
            }
          }
        }
      }

      .item-cta {
        .title-cta {
          font-family: "ITC Avant Garde Gothic Std";
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 41px;
          letter-spacing: 0.08em;
          text-align: center;
          color: #4b6e83;
        }

        a {
          font-family: "Avenir Next";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          border: 1px solid rgba(40, 72, 90, 0.5);
          padding: 22px 100px;
          text-decoration: inherit;
          margin: auto;
          display: table;
          text-align: center;
          min-width: 280px;
          transition: all ease 0.3s;
          &:hover {
            color: white;
            background-color: #28485a;
            transition: all ease 0.3s;
          }
          @include media-breakpoint-down(sm) {
            padding: 17px 40px;
            min-width: 230px;
          }
        }
      }
    }
  }

  .section-mot-du-fondateur {
    background-position: center;
    position: relative;
    &:before {
      content: "";
      background-color: black;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.5;
      z-index: 1;
    }
    .filigrane-b {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
    }

    .le-mot-du-fondateur {
      .cadre {
        border: 1px solid white;
        padding: 70px 30px 100px 30px;
      }
      .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.56;
      }

      .text {
        font-family: "Poppins";
        font-style: italic;
        font-weight: 300;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        color: #ffffff;
        @include media-breakpoint-down(md) {
          font-size: 24px;
          line-height: 30px;
        }
        @include media-breakpoint-down(md) {
          font-size: 20px;
          line-height: 26px;
        }
      }

      .signature {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .section-a-propos {
    margin-top: -150px;
    z-index: 2;
    position: relative;
    @include media-breakpoint-down(md) {
      margin-top: 60px;
      text-align: center;
    }
    .block-img-a-propos {
      height: 950px;
      width: 100%;
      overflow: hidden;
      @include media-breakpoint-down(md) {
        height: 500px;
      }

      .img-a-propos {
        height: 950px;
        width: 100%;
        background-size: cover;
        background-position: center;
        transform: scale(1);
        transition: all ease 0.8s;
        @include media-breakpoint-down(md) {
          height: 500px;
        }
      }

      &:hover {
        .img-a-propos {
          transform: scale(1.04);
          transition: all ease 0.8s;
        }
      }
    }
    h2 {
      font-weight: 400;
      text-align: left;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
    .text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: $grey-alt;
    }
  }

  .section-actu {
    h2 {
      font-family: "ITC Avant Garde Gothic Std";
      font-style: normal;
      font-weight: 400;
    }
    .custom-card {
      h2 {
        font-family: "ITC Avant Garde Gothic Std";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        text-transform: inherit;
      }
      .card-img-container {
        height: 530px;
        .card-img {
          height: 530px;
        }
      }
      .details {
        padding: 10px 20px;
        h2 {
          font-family: "ITC Avant Garde Gothic Std";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }
}
