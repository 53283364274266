/*
|--------------------
|       404
|--------------------
*/

#page-404 {
    .section-content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background-color: $very-dark-grey;
        color: $white;
        text-align: center;
        text-decoration: none !important;

        .item-title {
            font-size: 88px;
            font-weight: 300;
            text-decoration: none !important;
            @include media-breakpoint-down(lg) {
                font-size: 50px;
            }
            @include media-breakpoint-down(md) {
                font-size: 34px;
            }
        }
        .link-white {
            color: white;
            text-decoration: none !important;
            a {
                color: white;
                text-decoration: none !important;
            }
        }
    }
}
