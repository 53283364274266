/*
|--------------------
|     PAGE Communiqués
|--------------------
*/

.section-communiques {
  .elem-communique {
    position: relative;
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid $grey;
    }

    &:hover {
      .infos,
      .link {
        transition: all ease 0.2s;
        opacity: 0.6;
      }
    }

    .infos {
      float: left;
      width: 90%;
      transition: all ease 0.2s;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      .date {
        font-size: 18px;
        opacity: 0.5;
        font-style: italic;
      }
    }

    .link {
      position: absolute;
      right: 0;
      top: calc(50% - 15px);

      &:after {
        content: "";
        height: 30px;
        width: 30px;
        vertical-align: middle;
        align-items: center;
        display: flex;
        background-position: center;
        background-image: url("../img/icon/download.svg");
      }
    }
  }
}
