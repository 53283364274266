/*
|--------------------
|      SINGLE
|--------------------
*/

.post-date {
    font-size: 12px;
    color: rgba($grey, 0.6);
    text-transform: uppercase;
    font-weight: 400;
}

.post-excerpt {
    font-size: 22px;
    color: rgba($very-dark-grey, 0.6);
    font-weight: 400;
}

#block-gallery {
    .infos {
        width: 100%;
        #slides-gallery-activ {
            width: 100%;
            overflow: hidden;
            .swiper-slide {
                .card-img {
                    max-height: 140px;
                    max-width: 240px;
                    @include media-breakpoint-down(sm) {
                        max-height: 80px;
                        max-width: 120px;
                    }
                }
            }
        }
    }
}
