/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/

@font-face {
    font-family: "ITC Avant Garde Gothic Std";
    src: url("../fonts/avant-garde/AvantGardeLT-Book.eot");
    src: url("../fonts/avant-garde/AvantGardeLT-Book.eot?#iefix") format("embedded-opentype"),
        url("../fonts/avant-garde/AvantGardeLT-Book.woff2") format("woff2"),
        url("../fonts/avant-garde/AvantGardeLT-Book.woff") format("woff"),
        url("../fonts/avant-garde/AvantGardeLT-Book.ttf") format("truetype"),
        url("../fonts/avant-garde/AvantGardeLT-Book.svg#AvantGardeLT-Book") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ITC Avant Garde Gothic Std";
    src: url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.eot");
    src: url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.eot?#iefix") format("embedded-opentype"),
        url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.woff2") format("woff2"),
        url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.woff") format("woff"),
        url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.ttf") format("truetype"),
        url("../fonts/ITCAvantGardeStdXL/ITCAvantGardeStd-XLt.svg#ITCAvantGardeStd-XLt") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "ITC Avant Garde Gothic Std";
    src: url("../fonts/avant-garde/ITCAvantGardeStd-XLt.woff") format("woff");
    font-weight: 225;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/avenir-next/AvenirNextLTW01RegularRegular.eot");
    src: url("../fonts/avenir-next/AvenirNextLTW01RegularRegular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/avenir-next/AvenirNextLTW01RegularRegular.woff2") format("woff2"),
        url("../fonts/avenir-next/AvenirNextLTW01RegularRegular.woff") format("woff"),
        url("../fonts/avenir-next/AvenirNextLTW01RegularRegular.svg#AvenirNextLTPro") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next";
    src: url("../fonts/avenir-next/AvenirNextLTPro-Bold.eot");
    src: url("../fonts/avenir-next/AvenirNextLTPro-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/avenir-next/AvenirNextLTPro-Bold.woff2") format("woff2"),
        url("../fonts/avenir-next/AvenirNextLTPro-Bold.woff") format("woff"),
        url("../fonts/avenir-next/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Helvetica";
    src: url("../fonts/helvetica/Helvetica.eot");
    src: url("../fonts/helvetica/Helvetica.eot?#iefix") format("embedded-opentype"),
        url("../fonts/helvetica/Helvetica.woff2") format("woff2"),
        url("../fonts/helvetica/Helvetica.woff") format("woff"),
        url("../fonts/helvetica/Helvetica.svg#Helvetica-Bold") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-Thin.eot");
    src: url("../fonts/poppins/Poppins-Thin.eot?#iefix") format("embedded-opentype"),
        url("../fonts/poppins/Poppins-Thin.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-Thin.woff") format("woff"),
        url("../fonts/poppins/Poppins-Thin.svg#Poppins-Thin") format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-ExtraLight.eot");
    src: url("../fonts/poppins/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"),
        url("../fonts/poppins/Poppins-ExtraLight.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-ExtraLight.woff") format("woff"),
        url("../fonts/poppins/Poppins-ExtraLight.svg#Poppins-ExtraLight") format("svg");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-Light.eot");
    src: url("../fonts/poppins/Poppins-Light.eot?#iefix") format("embedded-opentype"),
        url("../fonts/poppins/Poppins-Light.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-Light.woff") format("woff"),
        url("../fonts/poppins/Poppins-Light.svg#Poppins-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-Medium.eot");
    src: url("../fonts/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
        url("../fonts/poppins/Poppins-Medium.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-Medium.woff") format("woff"),
        url("../fonts/poppins/Poppins-Medium.svg#Poppins-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins/Poppins-SemiBold.eot");
    src: url("../fonts/poppins/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/poppins/Poppins-SemiBold.woff2") format("woff2"),
        url("../fonts/poppins/Poppins-SemiBold.woff") format("woff"),
        url("../fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
