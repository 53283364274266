/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/
.banner {
  min-height: 920px;
  color: white;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-color: $blue2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: relative;
  @include media-breakpoint-down(lg) {
    min-height: 600px;
  }
  &:before {
    content: "";
    min-height: 920px;
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    @include media-breakpoint-down(lg) {
      min-height: 600px;
    }
  }
  .suptitle {
    font-family: "Avenir Next";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    text-transform: uppercase;
  }
  .elem-banner {
    min-height: 920px;
    @include media-breakpoint-down(lg) {
      min-height: 600px;
    }
    .txt-c {
      font-family: "Poppins";
    }
  }
  &.banner-actus {
    min-height: 768px;
    &:before {
      content: "";
      min-height: 768px;
    }
    .elem-banner {
      min-height: 768px;
    }
  }
}
.ariane {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #c4c4c4;
  margin-top: 210px;
  margin-left: 15px;
  position: absolute;
  z-index: 1;
  text-decoration: initial;
  @include media-breakpoint-down(lg) {
    display: none;
  }
  a,
  span {
    color: white;
    text-decoration: initial;
  }
  span {
    padding: 0 8px;
  }
}
/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
  cursor: pointer;
  .custom-card-link {
    display: block;
    text-decoration: none;
    overflow: hidden;
    pointer-events: none;
    cursor: default;
  }

  /*
  * Card img
  */
  .card-img-container {
    position: relative;
    overflow: hidden;
    height: 280px;
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    .card-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transition: transform 2s $easeOutExpo;
      will-change: transform;
      height: 280px;
      @include media-breakpoint-down(md) {
        height: 300px;
      }
    }
  }

  /*
  * Card content
  */
  .card-content {
    .card-date {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $blue2-light;
    }

    .card-title {
      font-family: "ITC Avant Garde Gothic Std";
      font-style: normal;
      text-align: left;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: black;
      text-transform: initial;
    }

    .card-content {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $grey;
      .more {
        text-decoration-line: underline;
        color: $blue2;
      }
    }
  }

  /*
  * Hover state
  */
  &:hover {
    .card-img-container {
      .card-img {
        transform: scale(1.05);
      }
    }
  }
  &.first-item {
    .card-date {
      font-size: 20px;
      line-height: 30px;
    }
    .card-title {
      font-weight: 400;
      font-family: "ITC Avant Garde Gothic Std";
    }
    .card-img-container {
      height: 660px;
      @include media-breakpoint-down(md) {
        height: 300px;
      }
      .card-img {
        height: 660px;
        @include media-breakpoint-down(md) {
          height: 300px;
        }
      }
    }
  }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
  li {
    display: inline-block;

    a {
      transition: all 0.3s ease-out;
      padding: 0 8px;

      &:hover {
        color: $grey;
      }
    }

    .current {
      padding: 0 8px;
      font-weight: 800;
      color: $grey;
    }
  }
}

/*
|
| CMS
|------
*/
.cms {
  color: $grey-alt;
  span {
    display: inline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 15px;
  }

  h1 {
    @extend .title-xxl;
  }

  h2 {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 44px;
    text-align: initial;
    color: $blue2;
    margin-top: 30px;
    margin-bottom: 10px;
    @include media-breakpoint-down(xl) {
      font-size: 34px;
      line-height: 38px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 28px;
      line-height: 32px;
    }
  }

  h3 {
    @extend .title-lg;
  }

  h4 {
    @extend .title-md;
  }

  h5 {
    @extend .title-sm;
  }

  h6 {
    @extend .title-xs;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  strong {
    font-weight: 800;
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  ul {
    padding-left: 0;

    li {
      position: relative;
      list-style-type: none;

      &:before {
        content: ">";
        font-family: "Poppins";
        font-weight: 500;
        font-size: 18px;
        position: absolute;
        color: $blue2;
        left: -15px;
      }

      margin-bottom: 20px;
    }
  }

  ol {
    margin-bottom: 20px;
    padding-left: 20px;
  }

  &.cms-no-break {
    p {
      margin-bottom: 0;
    }
  }

  blockquote {
    position: relative;

    width: 80%;

    margin-right: 0;
    margin-left: auto;
    padding: 130px 0;

    font-size: 30px;
    line-height: 1.5em;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin: auto;
    }

    &:before {
      content: "";

      position: absolute;

      background-image: url("../img/icon/quote-left.svg");

      width: 50px;
      height: 50px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(-60px) translateY(-30px);
    }

    &:after {
      content: "";

      position: absolute;
      right: 0;

      background-image: url("../img/icon/quote-right.svg");

      width: 50px;
      height: 50px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(0) translateY(-50px);

      @include media-breakpoint-down(md) {
        transform: translateX(60px) translateY(-50px);
      }
    }
  }
}

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: $headerHeight;
}

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block;

  &.cl-center {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: $black;

    circle {
      stroke: $black;
    }
  }

  &.ajax-loader {
    display: none;
  }
}

.gform_fields {
  margin: 0 !important;
}
.gform_confirmation_wrapper {
  vertical-align: middle;
  align-items: center;
  margin: auto;
  display: grid;
}
.gform_wrapper {
  font-family: "Poppins";
  font-size: 18px;
  color: white;
  letter-spacing: 0;

  .gform_title,
  .gform_required_legend,
  .gform_validation_errors {
    display: none;
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: white;
  }
  a {
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: white;
  }

  .gfield {
    margin-bottom: 15px;
  }

  label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: white;
  }

  input[type="text"],
  input[type="email"],
  textarea,
  select {
    border: 1px solid white;
    box-sizing: border-box;
    padding: 13px !important;
    background: none;
    color: white;

    &::placeholder {
      opacity: 0.5;
      font-family: "Poppins";
      font-size: 18px;
      color: white;
      letter-spacing: 0;
    }
  }

  select {
    font-family: "Avenir Next";
    font-size: 18px !important;
    color: white;
    letter-spacing: 0;
    border: 1px solid #d4d4d4;
  }

  textarea {
    height: 180px !important;
  }

  .ginput_container_consent {
    color: white;
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    text-align: left;
    font-weight: 400;
    position: relative;

    a {
      text-decoration: underline;
    }

    .gfield_required {
      display: none;
    }

    input[type="checkbox"] {
      position: absolute;
      cursor: pointer;
      border: none;
      left: 0;
      top: 5px;
      padding: 0;
      margin: 0;
    }

    input[type="checkbox"]:before {
      content: "";
      display: block;
      position: absolute;
      width: 21px;
      height: 21px;
      top: 0;
      left: 0;
      border: 1px solid white;
      background-color: transparent;
    }

    input[type="checkbox"]:checked:after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 3px;
      left: 3px;
      background: white;
    }

    label {
      margin-top: 4px;
      margin-left: 35px;

      @include media-breakpoint-down(lg) {
        padding-right: 0px;
      }
    }
  }
  .gfield_required {
    color: white !important;
  }
  .gfield_description {
    font-family: "Avenir Next" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 14px !important;
    color: white;
  }
  .ginput_counter {
    color: white !important;
  }
  .gform_footer {
    position: relative;
    .gform_button {
      font-family: "Avenir Next";
      background-color: $blue2;
      transition: all ease 0.3s;
      border: 1px solid rgba(255, 255, 255, 0.5);
      font-size: 18px;
      color: white;
      letter-spacing: 0;
      text-align: center;
      padding: 22px 90px;
      display: inline-block;
      width: auto;
      margin: auto;
      cursor: pointer;
      margin-top: 20px !important;
      margin-bottom: 40px !important;
      &:hover {
        transition: all ease 0.3s;
        background-color: $blue2;
        color: white;
      }
    }
    .gform_ajax_spinner {
      position: absolute;
      right: 0;
      left: 0;
      top: -45px;
      margin: auto;
    }
  }
  .gform_wrapper .gfield_error .gfield_repeater_cell label,
  .gform_wrapper .gfield_error label,
  .gform_wrapper .gfield_error legend,
  .gform_wrapper .gfield_validation_message,
  .gform_wrapper .validation_message,
  .gform_wrapper [aria-invalid="true"] + label,
  .gform_wrapper label + [aria-invalid="true"] {
    color: #ff7758;
  }
}

.swiper-buttons {
  top: -70px;
  right: 30px;
  position: absolute;

  .button-next,
  .button-prev {
    height: 54px;
    width: 54px;
    float: left;
    margin-left: 15px;
    position: relative;
    cursor: pointer;
    border: 1px solid #28485a;
    box-sizing: border-box;
    border-radius: 50%;
    transition: all ease 0.3s;
    background-color: #fff;

    &:before {
      content: "";
      background-image: url("../img/icon/arr.svg");
      background-size: cover;
      width: 18px;
      height: 8px;
      position: absolute;
      top: 21px;
      left: 17px;
    }

    &.button-prev {
      &:before {
        content: "";
        top: 24px;
        left: 17px;
        transform: rotate(180deg);
        background-image: url("../img/icon/arr.svg");
      }
    }
    &.swiper-button-disabled {
      opacity: 0.2;
    }
  }

  .sp-prod-button-prev {
    margin-left: 15px;

    &:before {
      content: "";
      transform: rotate(180deg);
    }
  }
}

.iziModal {
  max-width: 40% !important;
  @include media-breakpoint-down(lg) {
    max-width: 50% !important;
  }
  @include media-breakpoint-down(md) {
    max-width: 50% !important;
  }
  @include media-breakpoint-down(sm) {
    max-width: 90% !important;
  }
  .icon-close {
    font-family: "NexaRegular";
    transform: rotate(45deg);
    color: white;
    text-shadow: 0px 0px 1px black;
    line-height: 35px;
    font-size: 50px;
    position: fixed;
    top: 0px;
    right: 7px;
    z-index: 1;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &:focus {
      display: none;
    }
  }
  .modal-body {
    .details {
      padding: 15px;
    }
  }
  .iziModal-content {
    padding: 0 0 10px 0 !important;
  }
}

.numbers {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
.chiffre {
  .number {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 100px;
    line-height: 100px;
    color: white;
    @include media-breakpoint-down(lg) {
      font-size: 70px;
      line-height: 80px;
    }
    @include media-breakpoint-down(md) {
      font-size: 60px;
      line-height: 85px;
    }
  }
  .unity {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 64px;
    line-height: 96px;
    color: white;
    @include media-breakpoint-down(lg) {
      font-size: 44px;
      line-height: 96px;
    }
  }
  .texte {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: white;
  }
}
