/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  padding: 20px 0 15px 0;
  background-color: $blue2;
  z-index: 8;

  a {
    font-family: "Poppins";
    color: $white;
    text-decoration: initial;
    font-weight: 400;
  }

  .logo-footer {
    height: 160px;
    margin: auto;
    display: block;
  }

  hr {
    opacity: 0.1;
    background-color: white;
  }

  .header-container {
    text-align: left;
    padding-left: 0px;
  }

  .copy {
    .item {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $white;
      display: inline;
      @include media-breakpoint-down(md) {
        display: block;
        a {
          display: block;
        }
      }

      a,
      button {
        color: $white;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        transition: all ease 0.3s;
        text-decoration: none;

        &:hover {
          opacity: 0.6;
          transition: all ease 0.3s;
        }
      }
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }

    &.txt-r {
      text-align: right;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }
  .item-menu {
    .copy {
      width: 120px;
      .linkedin {
        background-image: url("../img/icon/linkedin.svg");
        height: 15px;
        width: 15px;
        display: block;
        float: right;
      }
    }
  }
  .menu-item-has-children {
    .nav-drop {
      top: initial;
      bottom: 0;
      a {
        color: $blue2;
        font-weight: 400;
      }
    }
  }
  .ft-bottom {
    font-family: "Poppins";
    font-weight: 300;
    .copy {
      a,
      button {
        font-family: "Poppins";
        font-weight: 300;
        @include media-breakpoint-down(xl) {
          font-size: 13px;
          line-height: 23px;
        }
        @include media-breakpoint-down(md) {
          font-size: 16px;
          line-height: 28px;
        }
      }
      &.c-left,
      &.c-right {
        @include media-breakpoint-down(lg) {
          text-align: center !important;
        }
      }
    }
  }
  .ot-sdk-show-settings {
    color: $white;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    transition: all ease 0.3s;
    text-decoration: none;
  }
}
